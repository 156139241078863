import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {replaceQuestion} from "../../store/question/questionSlice";

export default function RemoveQuestionModal({closeModal, isOpen, id, pid, questions}) {

    const [params, setParams] = useState(null)
    const dispatch = useDispatch();

    function findQuestionOption() {
        if (!questions.length) return
        const parentID = questions.findIndex(par => par.id === pid)
        let optionID = null
        if (parentID === -1) return
        if (id && questions[parentID].options && questions[parentID].options.length ) optionID = questions[parentID].options.findIndex(opt=>opt.id===id)
        setParams({parentID, optionID, id, pid, message: questions[parentID].message})
    }

    async function remove() {
        console.log('PARAMS', params)
        if (!questions.length) return
        let Questions = JSON.parse(JSON.stringify(questions));
        if (!params.id) {
            Questions = Questions.filter((_, i) => i !== params.parentID)
            // Убираем все следы удаленного объекта
            Questions.map(item=>{
                if (item.auto_link_id === params.pid) item.auto_link_id = null
                if (item.options && item.options.length) {
                    item.options.map(opt=>{
                        if (opt.child_id === params.pid) opt.child_id = null
                    })
                }
                return item
            })
        }
        else Questions[params.parentID].options.splice(params.optionID, 1)

        if (!Questions) return;
        dispatch(replaceQuestion(Questions))
        closeModal()
    }

    useEffect(() => {
        findQuestionOption()
    }, [isOpen])

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex justify-center p-4 mt-4  text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                       Удаление { id ? 'ответа' : 'вопроса' }!
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        {questions.length > 1 ? <p className="text-sm text-red-500">
                                            Удалить {id ? 'ответ' : 'вопрос'}?
                                        </p> : <p className="text-sm text-red-500">Вы не можете удалить единственный вопрос!</p>}
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="mr-4 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={closeModal}
                                        >
                                            Отмена
                                        </button>

                                        {questions.length > 1 && <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => remove()}
                                        >
                                            Удалить
                                        </button>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
