import {Link} from "react-router-dom";

const NoPage = () => {
    return <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl mt-20">
        <div className="block xl:inline">404</div>{' '}<br/>
        <div className="block text-orange-500 xl:inline">Страница не найдена!</div>
        <p className="text-sm text-blue-600 hover:text-blue-400"><Link to="/">На главную</Link></p>
    </h1>
}

export default NoPage