import {Outlet } from "react-router-dom";
import Header from "../components/layout/Header";
import AuthForm from "../components/auth/AuthForm";
import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useDispatch} from "react-redux";
import {setAppeals} from "../store/question/questionSlice";
import getDataAppeals from "../tools-scripts/getDataAppeals";

const IndexLayout = () => {
    const dispatch = useDispatch()
    const [AuthFormStatus, setAuthFormStatus] = useState(true)
    const [cookies, setCookie, removeCookie] = useCookies(['access_token'])
    const [desktop, setDesktop] = useState(false)

    async function getAppeals(key) {
        const appealsDataResult = await getDataAppeals(key)
        if (appealsDataResult && appealsDataResult.status === 401) {
            alert('Бездействие более 180 минут. Необходимо снова авторизироваться в системе!')
            removeCookie('access_token')
            window.location.reload()
        }
        else if (appealsDataResult) await dispatch(setAppeals(appealsDataResult.data || []))
        else alert('Не удалось загрузить обращения!')
    }

    function blockScreen(wrap) {
        if(wrap.offsetWidth < 768) setDesktop(true)
        else setDesktop(false)
    }

    function desktopHandler() {
        let wrap = document.getElementById('wrap')
        if (!wrap) return
        blockScreen(wrap)
        window.onresize = () => { blockScreen(wrap) }
    }

    useEffect(()=>{
        if (cookies.access_token) {
            setAuthFormStatus(false)
            getAppeals()
            desktopHandler()
        }
    }, [useCookies, getAppeals])

    return (
        <div id="wrap">
            {!desktop ?
                AuthFormStatus ?
                    <div className="w-full h-[100vh] flex justify-center items-center">
                        <AuthForm setAuthFormStatus={setAuthFormStatus} setCookie={setCookie} getAppeals={getAppeals}/>
                    </div>
                    :
                    <>
                        <Header/>
                        <div className="h-24"></div>
                        <div className="mx-auto max-w-7xl p-2">
                            <Outlet/>
                        </div>
                    </>
                :
                <div className="w-full h-[100vx] z-50 flex justify-center items-center flex-col fixed top-0 right-0 left-0 bottom-0">
                    <img src="/descktop.png" alt="descktop only"/>
                    <div className="text-center">
                        <p>Работа с конструктором возможна на устройствах</p>
                        <p>с шириной экрана более чем 768px</p>
                    </div>

                </div>
            }
        </div>
    )
};

export default IndexLayout;