import { Dialog, Transition } from '@headlessui/react'
import {Fragment} from 'react'
import {useSelector} from 'react-redux';
import SelectTechnicalIssues from "./SelectTechnicalIssues";
export default function AddToolsLabel({closeModal, isOpen, addTag, setToolsParam, toolsParam, appeal}) {

    let {selectedTechnicalIssues} = useSelector(state => state);

    function addKeywordTag() {
        if (!selectedTechnicalIssues) return
        addTag(toolsParam.parentID, toolsParam.name, toolsParam.label, selectedTechnicalIssues.text)
        closeModal()
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex justify-center p-4 mt-4  text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Перенаправление
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Выбирите ключевое слово для инструмента
                                        </p>
                                        {appeal && appeal.technical_issues && appeal.technical_issues.length ?
                                            <SelectTechnicalIssues  toolsParam={toolsParam} appeal={appeal}/>
                                            : 'Нет параметров для выбора'
                                        }
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="mr-4 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={closeModal}
                                        >
                                            Отмена
                                        </button>

                                        {appeal && appeal.technical_issues && appeal.technical_issues.length ? <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={()=>addKeywordTag()}
                                        >
                                            Выбрать
                                        </button> : ''}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
