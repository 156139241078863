import {useEffect, useRef} from "react";
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import UppercaseTitle from "../tools-scripts/uppercase-title";

export default function AppealInf({appeal, openSlideOver, setOpenSlideOver}) {
    const infoblock = useRef(null)
    const marginblock = useRef(null)

    function podsudTerr(val) {
        if (val === 1) return 'По заявителю'
        else if (val === 2) return 'По ответчику'
        else if (val === 3) return 'Другое (по имуществу, по месту штрафа)'
        else return 'Не определено...'
    }

    function openSlideOver() {
        setOpenSlideOver(true)
    }

    useEffect(()=>{
        const InfoblockHeight = infoblock.current.clientHeight
        marginblock.current.style.marginBottom = `${InfoblockHeight + 20}px`
    }, [appeal])

    return (
        <>
            <div ref={infoblock} className="w-full px-4 pt-2 fixed top-[100px] z-20">
                <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2 border">
                    <Disclosure>
                    {({ open }) => (
                        <>
                            <div className="flex justify-center items-center">
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span>{appeal.name}</span>
                                    <ChevronUpIcon
                                        className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-purple-500`}
                                    />
                                </Disclosure.Button>
                                {appeal.technical_issues && appeal.technical_issues.length ? <div
                                    className="ml-2 h-[25px] w-[25px] cursor-pointer hover:bg-red-200 rounded-[50%] transition text-red-500"
                                    onClick={() => setOpenSlideOver(true)}>
                                    <InformationCircleIcon/>
                                </div> : ''}
                            </div>

                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                <ul>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Субъект:</span> {appeal.fizyur === 1 ? 'Юридическое лицо' : 'Физическое лицо'}
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Процесс:</span> {appeal.process}
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Производство:</span> {appeal.proizv}
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Подкатегория 1:</span> {appeal.sub1}
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Подкатегория 2:</span> {appeal.sub2}
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Имумещственный/неимущественный:</span> {appeal.imneim === 1 ? 'Имущественный' : appeal.imneim === 2 ? 'Неимущественный' : 'Не определено...'}
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Родовая подсудность:</span> {appeal.podsud_rod === 1 ? 'Мировой участок' : appeal.podsud_rod === 2 ? 'Районный/городской суд' : 'Не определено...'}
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Сторона определения территориальной подсудности:</span> { podsudTerr(appeal.podsud_terr) }
                                    </li>
                                    <li className="border-b-2 pb-1 mb-1">
                                        <span className="font-bold">Госпошлина:</span> {appeal.gosposh === 1 ? 'Платиться' : 'Не платиться'}
                                    </li>
                                    <li>
                                        <span className="font-bold">Вопросы: </span>
                                        {
                                            appeal.questions.length ?
                                                <ul>
                                                    {
                                                        appeal.questions.map((app, idx)=><li key={idx}>{idx+1}: {UppercaseTitle(app)}</li>)
                                                    }
                                                </ul>
                                                : 'Не определено...'
                                        }
                                    </li>
                                </ul>
                            </Disclosure.Panel>

                        </>

                    )}
                </Disclosure>
                </div>
            </div>
            <div ref={marginblock}></div>
        </>

    )
}
