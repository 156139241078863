/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'

import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import getIcon from "../../tools-scripts/getIcon";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SelectTypes({id, pid, questions, selectedType}) {
    function clearList() {
        const parentQuestion = questions.find(itm=>itm.id === pid)
        if (parentQuestion && parentQuestion.type === 'options' && parentQuestion.options.length) {
            const option = parentQuestion.options.find(itm=>itm.id === id)
            if (!option) return 0
            const optionIDX = questions.findIndex(itm=>itm.id === option.child_id)
            if (optionIDX) return optionIDX
        }
        return 0
    }

    function filteredList() {
        let index = clearList() || 0
        if (index === -1) index = 0
        let newQuestions = questions.filter(item=>item.id !== pid)
        let matchFind = newQuestions[index-1]
        return matchFind ? matchFind : newQuestions[0]
    }

    const [selected, setSelected] = useState(filteredList())

    useEffect(()=>{
        selectedType(selected)
    }, [selected])


    return (
        <Listbox value={selected} onChange={setSelected}>
            {({open}) => (
                <div className="max-w-lg">
                    <Listbox.Label className="block text-sm font-medium text-gray-700 mt-2">Перенаправить к</Listbox.Label>
                    <div className="relative mt-1">
                        <Listbox.Button
                            className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="flex items-center">
                  <div className="h-6 w-6 flex-shrink-0 rounded-full mt-2" dangerouslySetInnerHTML={{__html: getIcon(selected.type)}}></div>
                  <span className="ml-3 block truncate">{selected.message}</span>
              </span>
                            <span
                                className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {
                                    questions.map((q) => {
                                        if (q.id !== pid) return (
                                            <Listbox.Option
                                                key={q.id}
                                                className={({active}) =>
                                                    classNames(
                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                    )
                                                }
                                                value={q}
                                            >
                                                {({selected, active}) => (
                                                    <>
                                                        <div className="flex items-center">
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: getIcon(q.type)}}></div>
                                                            <span
                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                            >
                                                        {q.message}
                                                    </span>
                                                        </div>

                                                        {selected ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                )}
                                                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        )
                                    })
                                }
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}
