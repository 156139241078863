import {useEffect, useRef, useState} from "react";
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import moment from "moment";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faList,
    faFloppyDisk,
    faTrash,
    faRocket,
    faArrowRight,
    faCheck,
    faWindowMaximize,
    faClose
} from '@fortawesome/free-solid-svg-icons'
import updateAppealStatement from "../tools-scripts/updateAppealStatement"
import updateAppealStatementResult from "../tools-scripts/UpdateAppealStatementResult"
import RocketTextModal from "../components/modals/RocketTextModal";
import ClearTextArea from "../components/modals/ClearTextArea";
import DeleteTextChain from "../components/modals/DeleteTextChain";
import getDataAppeals from "../tools-scripts/getDataAppeals";
import {setAppeal} from "../store/question/questionSlice";


const Text = () => {
    let dispatch = useDispatch()
    const [cookies] = useCookies(['access_token'])

    // создаем функцию задержки
    const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));

    const {appeal, questions} = useSelector(state => state)
    const [Questions, setQuestions] = useState(JSON.parse(JSON.stringify(questions)))
    const [selection, setSelection] = useState(null)

    const startFiled = {
        chain_id: Date.now() / 1000,
        title: 'Стартовое поле ввода',
        text: '',
    }

    const [baseTextarea, setBaseTextarea] = useState(appeal && appeal.statement ? appeal.statement : [startFiled])
    const [textTMP, setTextTMP] = useState(appeal && appeal.statement ? appeal.statement : [startFiled])
    const [textSave, setTextSave] = useState(appeal && appeal.statement ? appeal.statement : [startFiled])

    const [resultBaseText, setResultBaseText] = useState(appeal && appeal.statement_result ? appeal.statement_result[0] : {text: ''})
    const [resultText, setResultText] = useState(appeal && appeal.statement_result ? appeal.statement_result[0] : {text: ''})

    const [saveTimestamp, setSaveTimestamp] = useState(null)
    const [inputFocusElem, setInputFocusElem] = useState(null)
    const [listParams, setListParams] = useState({open: false})
    const [list, setList] = useState([])

    const [isRocketModalOpen, setIsRocketModalOpen] = useState(false)
    const [isDeleteTextAreaOpen, setIsDeleteTextAreaOpen] = useState(false)
    const [isDeleteChainModalOpen, setIsDeleteChainModalOpen] = useState(false)
    const [deleteChainItem, setDeleteChainItem] = useState(null)

    const toolbar = useRef(null)
    const textbar = useRef(null)
    const listBox = useRef(null)

    const navigate = useNavigate()

    // Управление состоянием модального окна
    function openRocketModal() {
        setIsRocketModalOpen(true)
    }
    function closeRocketModal() {
        setIsRocketModalOpen(false)
    }

    // Управление состоянием модального окна (очистка шаблона)
    function openDeleteTextAreaModal() {
        setIsDeleteTextAreaOpen(true)
    }

    function closeDeleteTextAreaModal() {
        setIsDeleteTextAreaOpen(false)
    }

    // Управление состоянием модального окна (удаление текстового поля)
    function openDeleteChainModal(item) {
        if (!item) return
        setDeleteChainItem(item)
        setIsDeleteChainModalOpen(true)
    }

    function closeDeleteChainModal() {
        setIsDeleteChainModalOpen(false)
    }

    function listValidation() {
        if (listParams.open) {
            alert('Сначала нужно выбрать вопрос из списка')
            return false
        } else return true
    }

    // Выборка данных из свойства Select / Range
    function getSelection(e = null) {
        let editor = true
        let tagName = 'DIV'
        if (e) {
            if( e.code === 'Enter' ) return
            e = e.target.tagName === 'SELECT' ? e.target.parentNode : e.target
            setText(e)
            tagName = e.tagName
        }
        const textSelected = document.getSelection()
        if (textSelected) {
            const value = textSelected.toString()
            const coords = textSelected.getRangeAt(0).getBoundingClientRect()
            const clone = textSelected.getRangeAt(0).cloneRange()
            setSelection({textSelection: textSelected, value, coords, clone, editor, tagName, evn: e})
        } else setSelection(null)
    }

    // Добавляем инпут
    async function AddInput() {
        if (!listValidation()) return

        if (!selection) return
        if (!selection.editor) return alert('Вы не можете добавлять список в это поле!')

        const stamp = new Date() / 1000
        const Input = document.createElement("input");
        Input.id = 'input-' + stamp
        Input.classList = 'ml-3 mr-2 p-2 rounded border-solid border-2 border-green-300 min-w-[400px]'
        Input.placeholder = 'Вопрос из чата'

        if (selection.textSelection) {
            await selection.clone.surroundContents(Input);
            await selection.textSelection.removeAllRanges();
            await selection.textSelection.addRange(selection.clone);

            const INPUT_IN_DOM = document.getElementById(`input-${stamp}`)

            // Удаляем вложенный селект!
            if (INPUT_IN_DOM.tagName === 'INPUT' && INPUT_IN_DOM.childNodes.length) INPUT_IN_DOM.removeChild(INPUT_IN_DOM.childNodes[0])

            addListenerOnInput(INPUT_IN_DOM)

            Input.focus()
        }
    }

    // Функция которая накидывает слушатели на inputs
    function addListenerOnInput(input) {
        // Focus
        input.addEventListener('focus', ev => {
            if (listBox.current) return
            ListParams(ev)
            setInputFocusElem(ev.target)
        })
        // Focus out
        input.addEventListener('focusout', async ev => {
            if (listBox.current) ev.target.focus()
            await removeCheckInInputs()
            setTimeout(() => addToList(), 100)
        })
        // Key up
        input.addEventListener('keyup', async ev => {
            searchFilterList(ev.target.value)
        })
    }

    // Проверяем содержит ли инпут вопрос с опциями
    async function isLogicInput(id) {
        if (id) {
            const findID = list.find(item=>item.parent_id === +id)
            if (findID && findID.logic) {
                alert('Инпут содержит вопрос с опциями! Следует удалить соответствующие текстовые поля созданные ранее для опций')
                return false
            }
            else return true
        }
        else return true
    }

    // Function for List Params
    async function ListParams(ev) {
        if (!await isLogicInput(ev.target.dataset.id)) {
            ev.target.blur()
            return
        }

        setListParams({open: true})
        setTimeout(() => {
            if (ev && ev.target && listBox && listBox.current) {
                listBox.current.style.top = ev.target.offsetTop + ev.target.clientHeight + 10 + 'px'
                listBox.current.style.left = ev.target.offsetLeft + 'px'
                listBox.current.style.width = ev.target.clientWidth + 4 + 'px'
                listBox.current.style.opacity = 100
            } else console.error('Не найден List Box')
        }, 0)
    }

    // Добавляем вопросы в list (useEffect - Questions)
    function addToList() {
        if (!Questions && !Questions.length) return
        let arr = []
        for (let q of Questions) {
            let logic = false
            // проверяем на отношение опции к логическому разветвлению
            if (q.options && q.options.length) {
                for (let o of q.options) {
                    if (o.child_id) logic = true
                }
            }
            arr.push({
                show: true,
                checked: q.checked || false,
                logic,
                parent_id: q.id,
                text: q.message
            })
        }
        setList(arr)
    }

    // Вписываем выбранный list option в input (value, data-id)
    async function addValueInInput(opt) {
        if (!opt) return

        if (selection.evn.id === 'result' && opt.logic || selection.evn.parentElement.id === 'result' && opt.logic) return alert('Тип вопроса не допустим для резолютивная части')

        if (inputFocusElem) {
            inputFocusElem.value = opt.text
            inputFocusElem.dataset['id'] = opt.parent_id
            setTimeout(() => inputFocusElem.blur(), 0)
            let arr = []
            for (let q of Questions) {
                if (q.id === opt.parent_id) q.checked = true
                arr.push(q)
            }
            setQuestions(arr)
            await findLogicMethod(opt.parent_id)
        }
        setListParams({open: false})

        if (inputFocusElem && inputFocusElem.parentElement && inputFocusElem.dataset.id) {
            const IDX = inputFocusElem.parentElement.dataset.idx
            const HTML = inputFocusElem.parentElement.innerHTML || ''
            await innerText(IDX, HTML)
        }
    }

    // Фильтруем список list
    function searchFilterList(val) {
        let filterList = list.filter(l => l.text.toLowerCase().indexOf(val.toLowerCase()) !== -1)
        if (filterList) setList(filterList)
    }

    // Убираем из списка не отметки если вопрос не задйствован в инпутах
    async function removeCheckInInputs() {
        let arr = []
        for (let q of Questions) {
            let inputID = await document.querySelector(`[data-id="${q.id}"]`)
            if (!inputID) q.checked = false
            else q.checked = true
            arr.push(q)
        }
        setQuestions(arr)
    }

    // Ищем логические разветвления в вопросе
    async function findLogicMethod(id) {
        if (!id) return
        let parent = inputFocusElem.parentNode

        const Question = Questions.find(itm => itm.id === +id)

        let uniqOptionsCount = []

        if (Question && (Question?.options && Question.options.length)) {
            let idx = 0
            for (let item of Question.options) {
                uniqOptionsCount.push({
                    chain_id: Date.now() / 1000 + idx,
                    title: Question.message,
                    subtitle: item.text,
                    text: '',
                    id: Question.id,
                    option_id: item.id
                })
                idx++
            }

            let newBaseTextarea = JSON.parse(JSON.stringify(textTMP))

            let res = []
            for (let item of newBaseTextarea) {
                if (item.chain_id === +parent.dataset.idx) {
                    item.text = parent.innerHTML
                    //item.selected = true
                }
                res.push(item)
            }
            if (uniqOptionsCount.length) {
                setTextTMP([...res, ...uniqOptionsCount])
                setBaseTextarea([...res, ...uniqOptionsCount])
                setTimeout(async () => {
                    await backValueInInputs()
                    await setListenerOnAllInputs()
                }, 0)
                //setTimeout(() => disabledSelects(), 0)
            }
        }
    }

    // Анактивируем select после логической разбивики
    function disabledSelects() {
        const disabledDiv = document.querySelectorAll('div[disabled] > input')
        if (disabledDiv && disabledDiv.length) {
            for (let itm of disabledDiv) {
                itm.disabled = true
                itm.style.border = '1px solid rgb(212 212 212)'
                itm.style.color = 'rgb(136 136 136)'
            }
        }
        setTimeout(() => backValueInInputs(), 0)
    }

    // Возвращаем value инпутам по из dadaset меткам
    async function backValueInInputs() {
        let inputs = document.querySelectorAll('input')
        if (inputs) {
            for (let i of inputs) {
                if (i.dataset.id) {
                    let question = Questions.find(item => item.id === +i.dataset.id)
                    if (question) i.value = question.message
                }
            }
        }
    }

    // Возвращаем чеки в вопросы
    function backCheckedInQuestions() {
        for (let q of Questions) {
            let input = document.querySelector(`[data-id="${q.id}"]`)
            if (input) q.checked = true
            else q.checked = false
        }
    }

    function AddNewWindow() {
        const newWindow = {
            chain_id: Date.now() / 1000,
            title: 'Поле для ввода текста',
            subtitle: null,
            text: '',
            id: null,
            option_id: null
        }
        setTextTMP([...textTMP, newWindow])
        setBaseTextarea([...baseTextarea, newWindow])
    }

    // Обновляем appeal
    async function restartAppeal() {
        const appealsDataResult = await getDataAppeals(cookies.access_token)
        if (appealsDataResult && appealsDataResult.data && appealsDataResult.data.length) {
            const findAppeal = appealsDataResult.data.find(a=>a._id === appeal._id)
            await dispatch(setAppeal(findAppeal))
        }
    }

    // Сохранаем изменения
    async function saveTextInLocalStorage() {
        await updateAppealStatement(appeal._id, textTMP)
        await updateAppealStatementResult(appeal._id, [resultBaseText])
        setTextSave(textTMP)
        setResultText(resultBaseText)
        setSaveTimestamp(moment().add(5, 'second').format('X'))
        await restartAppeal()
    }

    // Удаляем весь statement
    async function clearTextInLocalStorageAndInTextarea() {
        closeDeleteTextAreaModal()
        const payload = [startFiled]
        setTextTMP(payload)
        setBaseTextarea(payload)
        setResultBaseText({text: ''})
        setResultText({text: ''})
        await updateAppealStatement(appeal._id, [])
        await updateAppealStatementResult(appeal._id, [])
        await restartAppeal()
    }

    // Удаляем текстовое поле
    async function deleteChain() {
        if (deleteChainItem.id) {
            await setTextTMP(textTMP.filter(item=>item.id !== deleteChainItem.id))
            await setBaseTextarea(baseTextarea.filter(item=>item.id !== deleteChainItem.id))
            setList(list.map(l=>{
                if (l.parent_id === deleteChainItem.id) l.checked = false
                return l
            }))
            const input = document.querySelector(`[data-id='${deleteChainItem.id}']`)
            if (input) input.remove()
        }
        else {
            await setTextTMP(textTMP.filter(item=>item.chain_id !== deleteChainItem.chain_id))
            await setBaseTextarea(baseTextarea.filter(item=>item.chain_id !== deleteChainItem.chain_id))
        }
        await closeDeleteChainModal()
    }

    // Вставляем данные текста в setTextTMP
    async function setText(ev) {
        if (!ev) return
        const IDX = ev.dataset.idx
        const HTML = ev.innerHTML
        await innerText(IDX, HTML)
    }
    async function innerText(IDX, HTML) {
        if (IDX === 'result') {
            setResultBaseText({text: HTML})
            return
        }
        const parseBaseTextarea = JSON.parse(JSON.stringify(textTMP))
        const newBaseText = parseBaseTextarea.map(itm => {
            if (+itm.chain_id === +IDX) itm.text = HTML
            return itm
        })
        setTextTMP(newBaseText)
    }

    // Выставляем длину toolbar
    function setWidthOnToolBar() {
        if (!toolbar || !textbar || !textbar.current || !textbar.current.clientWidth) return
        const WIDTH = textbar.current.clientWidth
        toolbar.current.style.width = WIDTH + 'px'
    }

    async function setListenerOnAllInputs() {
        const selectElems = document.querySelectorAll('input')
        if (selectElems) selectElems.forEach(el => {
            addListenerOnInput(el)
        })
        await backCheckedInQuestions()
    }

    // Проверяем соответсвуют ли инпуты существующим вопросам
    async function checkIsQuestion() {
        const inputs = document.querySelectorAll('input')
        if (inputs && inputs.length) {
            for (let i of inputs) {
                const id = i.dataset.id
                if (id && Questions && Questions.length) {
                    const findQuestion = Questions.find(itm=>itm.id === +id)
                    if (!findQuestion) i.style.border = '3px solid red'
                }
            }
        }
    }

    // USE EFFECTS -----------------------------------------------------------------------------
    useEffect(() => {
        if (!appeal || !questions.length) return navigate("/")
        if (appeal && appeal.statement && !appeal.statement.length) {
            setBaseTextarea([startFiled])
            setTextTMP([startFiled])
        } else setTimeout(() => disabledSelects(), 0)
        setWidthOnToolBar()
        window.addEventListener('resize', () => setWidthOnToolBar())
        // Накидываем слушателя на селекты
        setListenerOnAllInputs()
    }, [appeal])

    useEffect(() => {
        addToList()
        checkIsQuestion()
    }, [questions, textbar])

    // Сохранем 10 сек последней работы
    useEffect(()=>{
        let promiseSave = new Promise((resolve, reject)=>{
            let timeNow = moment().format('X')
            if (JSON.stringify(textTMP) !== JSON.stringify(textSave) && timeNow > +saveTimestamp) return resolve(true)
            //else if (JSON.stringify(resultBaseText.text) !== JSON.stringify(resultText.text) && timeNow > +saveTimestamp) return resolve(true)
        })

        promiseSave.then(status=>{
            if (status) saveTextInLocalStorage()
        })
    }, [textTMP, resultBaseText])

    return (
        <>
            <RocketTextModal
                closeRocketModal={closeRocketModal}
                isRocketModalOpen={isRocketModalOpen}
                textTMP={textTMP}
                appeal={appeal}
                resultText={resultBaseText}
            />
            <ClearTextArea
                closeDeleteTextAreaModal={closeDeleteTextAreaModal}
                isDeleteTextAreaOpen={isDeleteTextAreaOpen}
                clearTextInLocalStorageAndInTextarea={clearTextInLocalStorageAndInTextarea}
            />
            <DeleteTextChain
                closeDeleteChainModal={closeDeleteChainModal}
                isDeleteChainModalOpen={isDeleteChainModalOpen}
                deleteChain={deleteChain}
                deleteChainItem={deleteChainItem}
            />

            <div
                className="textarea__tools flex items-center justify-between w-full mb-3 fixed w-[97%] bg-white border border-gray-100 rounded shadow-md shadow-gray-100 pl-3 pr-3 z-50"
                ref={toolbar}>
                <div className="flex items-center">
                    {!listParams.open ?
                        <div className="group relative pr-3 flex items-center">
                            <div onClick={() => AddInput()}
                                 className="cursor-pointer p-2 hover:bg-gray-200 rounded transition">
                                <FontAwesomeIcon icon={faList}/>
                            </div>
                            <span
                                className="absolute top-11 left-0 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Добавить вопрос</span>
                        </div>
                        :
                        <div className="pr-3 flex items-center">
                            <div className="p-2 rounded transition">
                                <FontAwesomeIcon icon={faList} className="text-gray-200"/>
                            </div>
                        </div>
                    }
                    {!listParams.open ?
                        <div className="group relative mr-3 pr-3 border-r flex items-center">
                            <div onClick={() => AddNewWindow()}
                                 className="cursor-pointer p-2 hover:bg-gray-200 rounded transition">
                                <FontAwesomeIcon icon={faWindowMaximize}/>
                            </div>
                            <span
                                className="absolute top-11 left-0 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Новое текстовое поле</span>
                        </div>

                        :
                        <div className="mr-3 pr-3 border-r flex items-center">
                            <div className="p-2 rounded transition">
                                <FontAwesomeIcon icon={faWindowMaximize} className="text-gray-200"/>
                            </div>
                        </div>
                    }
                    <div className="mr-3 pr-3 border-r flex items-center">
                        {!listParams.open && JSON.stringify(textTMP) !== JSON.stringify(textSave) || JSON.stringify(resultText.text) !== JSON.stringify(resultBaseText.text)
                            ?
                            <div className="group relative">
                                <div onClick={() => saveTextInLocalStorage()}
                                     className="mr-3 cursor-pointer p-2 hover:bg-gray-200 rounded transition">
                                    <FontAwesomeIcon icon={faFloppyDisk}/>
                                </div>
                                <span
                                    className="absolute top-11 left-0 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Сохранить шаблон</span>
                            </div>
                            :
                            <div className="mr-3 p-2 rounded transition">
                                <FontAwesomeIcon icon={faFloppyDisk} className="text-gray-200"/>
                            </div>
                        }
                        {!listParams.open && baseTextarea.length > 1 ?
                            <div className="group relative">
                                <div onClick={() => openDeleteTextAreaModal()}
                                     className="cursor-pointer p-2 hover:bg-red-200 rounded transition hover:text-red-800">
                                    <FontAwesomeIcon icon={faTrash}/>
                                </div>
                                <span
                                    className="absolute top-11 left-0 scale-0 rounded bg-red-800 p-2 text-xs text-white group-hover:scale-100">Очистить шаблон</span>
                            </div>
                            :
                            <div className="rounded transition">
                                <FontAwesomeIcon icon={faTrash} className="text-gray-200"/>
                            </div>
                        }
                    </div>
                </div>

                <div className="flex items-center border-l pl-4">
                    {!listParams.open && textTMP.length && textTMP[0].text ?
                        <div className="group relative">
                            <div onClick={() => openRocketModal()}
                                 className="mr-3 cursor-pointer p-2 hover:bg-blue-100 rounded transition hover:text-blue-800">
                                <FontAwesomeIcon icon={faRocket}/>
                            </div>
                            <span
                                className="absolute top-11 right-0 scale-0 rounded bg-blue-800 p-2 text-xs text-white group-hover:scale-100">Сформировать тестовое заявление</span>
                        </div>
                        :
                        <div className="mr-3 p-2 rounded transition">
                            <FontAwesomeIcon icon={faRocket} className="text-gray-200"/>
                        </div>}
                </div>

            </div>

            <div className="mt-[50px] relative" ref={textbar}>

                {/*QUESTION LIST BLOCK*/}
                {listParams.open ? <div
                    className="opacity-0 absolute top-0 left-0 bg-white rounded p-2 drop-shadow-2xl transition ease-in-out delay-200 max-h-[500px] overflow-x-auto"
                    id="list-box"
                    ref={listBox}>
                    {list.length
                        ?
                        <div>
                            {list.map((l, i) => (
                                (l.checked && l.logic) ? '' : <div key={i}
                                                                   className={l.checked ? "text-[14px] border-b p-2 cursor-pointer hover:bg-gray-100 bg-green-100" : "text-[14px] border-b p-2 cursor-pointer hover:bg-gray-100"}
                                                                   onClick={() => addValueInInput(l)}>
                                    <div>{l.logic ? <FontAwesomeIcon icon={faArrowRight}/> : ''} {l.checked ?
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="text-green-600"
                                        />
                                        : ''
                                    }
                                        {l.text}
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div>Нет списка.</div>
                    }
                </div> : ''}

                {/*TEXTAREA BOXES*/}
                {baseTextarea.map((item, idx) => (
                    <div key={item.chain_id} className={item.subtitle ? "p-6 border bg-amber-50 mb-6" : "pb-6"}>
                        {idx + 1 !== 1 && !item.subtitle ? <div className="border-b border-gray-200 w-full pt-2"></div> : ''}
                        <div className="flex items-center justify-between">
                            <div>
                                <h3 className="font-medium pt-2">{item.subtitle ? <span className="font-bold text-blue-600">Вопрос:</span> : ''} {item.title}</h3>
                                {item.subtitle ? <h4 className="font-medium mb-3"><span className="font-bold text-blue-600">Опция:</span> {item.subtitle}</h4> : ''}
                            </div>
                            {idx + 1 !== 1 ?
                                <div className="cursor-pointer mr-3" onClick={() => openDeleteChainModal(item)}>
                                    <FontAwesomeIcon icon={faClose}/>
                                </div>
                                :
                                ''
                            }
                        </div>


                        <div className={item.selected ? 'textarea__box' : 'textarea__box min-h-2 h-auto'}>
                            <div
                                data-idx={item.chain_id}
                                contentEditable={item.selected || listParams.open ? false : true}
                                className="w-full h-[100%] bg-[#f4f4f4] rounded-[15px] p-4"
                                suppressContentEditableWarning={item.selected ? false : true}
                                onMouseUp={(e) => getSelection(e)}
                                onKeyDown={e=>e.code === 'Enter' ? e.preventDefault() : ''}
                                onKeyUp={(e) => getSelection(e)}
                                id={`edit-${item.chain_id}`}
                                disabled={item.selected ? true : false}
                                spellCheck="true"
                                dangerouslySetInnerHTML={{__html: item.text}}
                            ></div>
                        </div>
                    </div>
                ))}
                {/*Просительная (резолятивная) часть*/}
                <div className="mt-10 pb-6 border-t-8 border-blue-300 border-dashed">
                    <h3 className="font-medium pt-2 uppercase mt-6">Резолютивная часть </h3>
                    <div className={'textarea__box min-h-2 h-auto'}>
                        <div
                            data-idx='result'
                            contentEditable={true}
                            className="w-full h-[100%] bg-[#f4f4f4] rounded-[15px] p-4"
                            suppressContentEditableWarning={true}
                            onMouseUp={(e) => getSelection(e)}
                            onKeyDown={e=>e.code === 'Enter' ? e.preventDefault() : ''}
                            onKeyUp={(e) => getSelection(e)}
                            id='result'
                            spellCheck="true"
                            dangerouslySetInnerHTML={{__html: resultText.text}}
                        ></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Text;