import {Fragment, useEffect, useState, useRef} from 'react'
import { useLocation } from 'react-router-dom'
import {Popover, Transition} from '@headlessui/react'
import {
    ChatBubbleLeftEllipsisIcon,
    CalendarDaysIcon,
    ChatBubbleBottomCenterTextIcon,
    CalculatorIcon,
    MapPinIcon,
    UserCircleIcon
} from '@heroicons/react/24/outline'
import {ChevronDownIcon} from '@heroicons/react/20/solid'

import ModalQuestion from "../create-question/ModalQuestion";
import ChatModal from "../modals/ChatModal";
import {useSelector, useDispatch} from "react-redux";
import { setAppealsToggle, setPopoverStatus, setAppeals } from "../../store/question/questionSlice";
import getDataAppeals from "../../tools-scripts/getDataAppeals";
import {useCookies} from "react-cookie";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";

const solutions = [
    {
        name: 'Варианты ответов',
        description: 'Создание заранее подготовленных вариантов ответов',
        type: "options",
        icon: ChatBubbleLeftEllipsisIcon,
    },
    {
        name: 'Текст',
        description: 'Ожидает от пользователя введение текста',
        type: "text",
        icon: ChatBubbleBottomCenterTextIcon,
    },
    {
        name: 'Дата',
        description: "Ожидает от пользователя введение календарной даты.",
        type: "date",
        icon: CalendarDaysIcon,
    },
    {
        name: 'Число',
        description: 'Ожидает от пользователя введения числа',
        type: "number",
        icon: CalculatorIcon,
    },
    {
        name: 'Адрес',
        description: 'Ожидает от пользователя введения адреса',
        type: "address",
        icon: MapPinIcon,
    },
    {
        name: 'Имя',
        description: 'Ожидает от пользователя введения Ф.И.О.',
        type: "name",
        icon: UserCircleIcon,
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const [open, setOpen] = useState(false)
    const [openChat, setOpenChat] = useState(false)
    const [QParams, setQParams] = useState(solutions[0])
    const [cookies, setCookie, removeCookie] = useCookies(['access_token'])

    const LocationRoute = useLocation()

    let dispatch = useDispatch()

    let {addQuestionStatus, appealsToggle, questions, appeal, popoverStatus} = useSelector(state => state);
    const popover = useRef(null);

    async function appealsList() {
        const ERROR = (e) => {
            if (e) console.error('ERROR request tree', e)
            alert('Бездействие более 180 минут. Необходимо снова авторизироваться в системе!')
            removeCookie('access_token')
            window.location.reload()
        }
        try {
            const appealsDataResult = await getDataAppeals(cookies.access_token)
            if (appealsDataResult) await dispatch(setAppeals(appealsDataResult.data || []))
            else ERROR()
        }
        catch (e) {
            ERROR(e)
        }

        dispatch(setAppealsToggle(true))
        dispatch(setPopoverStatus(false))
    }

    function openChatModal() {
        setOpenChat(true)
    }

    function closeChatModal() {
        setOpenChat(false)
    }

    function logout() {
        removeCookie('access_token')
    }

    useEffect(()=>{
       if (addQuestionStatus || popoverStatus) popover.current?.click()
    }, [addQuestionStatus, popoverStatus])

    return (
        <section className="fixed w-full z-30">
            <ChatModal open={openChat} closeModal={closeChatModal} appeal={appeal} questions={questions}/>
            <ModalQuestion open={open} setOpen={setOpen} params={QParams}/>
            <Popover className="relative bg-white">
                <div className="mx-auto max-w-7xl px-4 sm:px-6">
                    <div
                        className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a href="/">
                                <span className="sr-only">Конструктор вопросов</span>
                                <img
                                    className="h-8 w-auto sm:h-10"
                                    src="/constructor_logo.svg"
                                    alt=""
                                />
                            </a>
                        </div>

                        {LocationRoute.pathname === '/text' && <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer">
                            <FontAwesomeIcon icon={faArrowCircleLeft} className="text-blue-500 text-[20px]"/>
                        </Link>}

                        {
                            !appealsToggle && appeal ?
                            <>
                                { LocationRoute.pathname !== '/text' &&
                                <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                                    <Popover className="relative">
                                        {({open, close}) => (
                                            <>
                                                <Popover.Button
                                                    ref={popover}
                                                    className={classNames(
                                                        open ? 'text-gray-900' : 'text-gray-500',
                                                        'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none'
                                                    )}
                                                >
                                                    <span>Добавить</span>
                                                    <ChevronDownIcon
                                                        className={classNames(
                                                            open ? 'text-gray-600' : 'text-gray-400',
                                                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </Popover.Button>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                >
                                                    <Popover.Panel
                                                        className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                                        <div
                                                            className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                                            <div
                                                                className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                                {solutions.map((item) => (
                                                                    <div
                                                                        key={item.name}
                                                                        onClick={() => {
                                                                            setOpen(true)
                                                                            setQParams(item)
                                                                        }}
                                                                        className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50 cursor-pointer"
                                                                    >
                                                                        <item.icon
                                                                            className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                                                            aria-hidden="true"/>
                                                                        <div className="ml-4">
                                                                            <p className="text-base font-medium text-gray-900 text-left">{item.name}</p>
                                                                            <p className="mt-1 text-sm text-gray-500 text-left">{item.description}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div
                                                                className="space-y-6 bg-gray-50 px-2 py-2 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8"></div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Popover>
                                </Popover.Group>
                                }
                                {questions.length ? <div className="text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer"
                                      onClick={() => openChatModal()}>Тестовый чат</div> : ''}
                            </> : ''
                        }

                        {appeal && questions.length && LocationRoute.pathname !== '/text' ? <Link to="/text" className="text-base font-medium text-gray-500 hover:text-gray-900 cursor-pointer">Текст заявления</Link> : ''}

                        <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                            {!appealsToggle && LocationRoute.pathname !== '/text' && <div
                                className="text-base font-medium text-gray-500 hover:text-gray-900 mr-4 cursor-pointer bg-blue-100 p-2 rounded-lg"
                                onClick={()=>appealsList()}
                            >
                                Список обращений
                            </div>}
                            <a href="/"
                               className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                               onClick={()=>logout()}
                            >
                                Выход
                            </a>
                        </div>
                    </div>
                </div>
            </Popover>
        </section>
    )
}
