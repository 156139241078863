import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {replaceQuestion} from "../../store/question/questionSlice";
import AlertQuestion from "../create-question/AlertQuestion";

export default function EditChildModal({closeModal, isOpen, id, pid, questions}) {

    const [childName, setChildName] = useState({value: ''})
    const [alertError, setAlertError] = useState('')
    const [params, setParams] = useState(null)
    const dispatch = useDispatch();

    function editChildName(val) {
        setChildName({value: val})
    }

    function findStartChildName() {
        if (!questions.length) return
        const parent = questions.find(par => par.id === pid)
        if (!parent || (!parent.options || !parent.options.length)) return
        const option = parent.options.find(opt => opt.id === id)
        setParams({id, pid, text: option.text})
        setChildName({value: option.text})
    }

    function addNewOptionText() {
        if (!childName.value) {
            setAlertError('Поле не может быть пустым!')
            closeAlertError()
            return;
        }
        if (!questions.length) return
        const parentID = questions.findIndex(p => p.id === params.pid)
        if (parentID === -1 || (!questions[parentID].options || !questions[parentID].options.length)) return
        const optionID = questions[parentID].options.findIndex(opt => opt.id === params.id)
        const Questions = JSON.parse(JSON.stringify(questions));
        if (optionID !== -1) Questions[parentID].options[optionID].text = childName.value
        dispatch(replaceQuestion(Questions))
        closeModal()
    }

    // Закрываем сообщение об ошибки через установленный промежуток времени
    function closeAlertError() {
        setTimeout(()=> setAlertError(''), 3000)
    }

    useEffect(() => {
        findStartChildName()
    }, [isOpen])

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex justify-center p-4 mt-4  text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Изменения названия варианта ответа
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Впишите новое название ответа и нажмите "Изменить"
                                        </p>
                                        <input
                                            id={id}
                                            type="text"
                                            name="price"
                                            value={childName.value}
                                            className="mt-4 block w-full rounded-md border-gray-300 pl-2 pr-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            onChange={ev => editChildName(ev.target.value)}
                                        />
                                        {
                                            alertError && <div className="relative top-[67px]"><AlertQuestion text={alertError}/></div>
                                        }
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="mr-4 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={closeModal}
                                        >
                                            Отмена
                                        </button>

                                        {questions.length > 1 && <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => addNewOptionText()}
                                        >
                                            Изменить
                                        </button>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
