import {PaperAirplaneIcon} from "@heroicons/react/20/solid";
import {useEffect, useState, Fragment} from "react";
import {XCircleIcon} from "@heroicons/react/20/solid";
import moment from "moment";
import {Transition} from '@headlessui/react';
import getIcon from "../../tools-scripts/getIcon";
import Datepicker from 'flowbite-datepicker/Datepicker';
import ru from "../../../node_modules/flowbite-datepicker/js/i18n/locales/ru.js";

Datepicker.locales.ru = ru.ru;

export default function Chat({questions, questionList, setQuestionsList}) {
    const [suggestions, setSuggestions] = useState([])
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState({text: '', status: false})

    // создаем функцию задержки
    const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));

    setTimeout(()=>scrollBox(),0)

    async function errorAlert(text) {
        setError({text, status: true})
        await delay(2000)
        setError({text: '', status: false})
    }

    // валидируем строку в input в зависимости от типа
    async function inputValidation(val) {
        if (!val) {
            await errorAlert('Поле не может быть пустым!')
            return
        }
        if (!val && !questionList.length && !questionList[questionList.length - 1].type) return
        const type = questionList[questionList.length - 1].type
        if (type === 'date') {
            let reg = moment(val, 'DD.MM.YYYY', true).isValid() ///^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g;
            if (reg) return true
            else {
                await errorAlert('Допускается формат - дд.мм.гггг')
                return false
            }
        } else if (type === 'number') {
            let regNumber = /^[0-9]*([,.]{1}[0-9]*)?$/;
            if (regNumber.test(val)) return true
            else {
                await errorAlert('Допускается формат ввод числа с одной точкой или одной запятой')
                return false
            }
        } else return true
    }

    // имитируем ожидание ...печатает
    async function loaderDelay(ms) {
        await delay(1000)
        if (!loader) setLoader(true)
        await delay(ms)
        setLoader(false)
    }

    // скролим к последнему элементу в чате
    function scrollBox() {
        let chatBox = document.getElementById('scroll')
        if (chatBox) setTimeout(() => chatBox.scrollIntoView({behavior: "smooth"}), 100)
    }

    // накидываем фокус на input
    async function inputFocus(question) {
        if (!question && !question.type && question.type !== 'questions') return
        setTimeout(() => {
            let el = document.querySelector('.inp')
            if (el) el.focus()
        }, 0)
    }

    // init datepicker plugin
    async function setDatePicker(question) {
        if (question.length) question = question[0]
        if (!question && !question.type && question.type !== 'questions' || question.type !== 'date') return
        await delay(100)
        const datepickerEl = document.getElementById('datepicker');
        console.log('datepickerEl', datepickerEl)
        if (!datepickerEl) return
        datepickerEl.value = moment().format('DD.MM.YYYY')
        new Datepicker(datepickerEl, {
            datepicker: true,
            language: "ru",
            orientation: "left top",
            weekStart: 1,
            autohide: true
        });
    }

    // получаем и выводим список с подсказками (адрес, имя)
    async function getSuggestions(val) {
        if (!questionList.length && !questionList[questionList.length - 1].type) return
        const type = questionList[questionList.length - 1].type
        let list = []
        if (type === 'address') list = await getSuggestionsOnDadata('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', val)
        else if (type === 'name') list = await getSuggestionsOnDadata('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio', val)
        else return
        setSuggestions(list)
    }

    // функция асинхронного запроса подсказок DADATA
    async function getSuggestionsOnDadata(url, query) {
        if (!url) return
        const method = 'POST'
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Token 47905e08ab894bc4b31cf8bbc24fce763cdffc19",
            "X-Secret": "6a50ed1a17eb6053c30820c63672f23c28bb1df7"
        }
        const count = 6
        let queryResult = await fetch(url, {method, headers, body: JSON.stringify({query, count})})
        let resJson = await queryResult.json()
        if (!resJson || !resJson.suggestions || !resJson.suggestions.length) return []
        return resJson.suggestions
    }

    // Вставляем подсказку в input
    function addSuggestionOnInput(val) {
        const el = document.querySelector('.inp')
        if (!el) return
        el.value = val
        setSuggestions([])
    }

    // последнее собщение в чате
    async function addLastMessageOnChat(message) {
        if (!questionList[questionList.length-1] || !questionList[questionList.length-1].idx) return
        else if (questionList[questionList.length-1].idx === questions[questions.length-1].idx) {
            if (message) await setQuestionsList(q => [...q, {message}])
            await loaderDelay(1000)
            await setQuestionsList(q => [...q, {bot: true, message: 'На этом все! Переходим к оплате и скачиванию документа.'}])
            await scrollBox()
            return false
        }
        else return true
    }

    // активация датепикера и фокуса
    async function datePickerAndFocusInputInit(question) {
        await delay(100)
        await setDatePicker(question)
        await delay(500)
        await inputFocus(question)
    }

    // подсчитываем задержку в зависимости от кол-ва символов в строке сообщения и опциях
    async function reduceCountDaley(nextQuestion) {
        const optionsCount = nextQuestion.options && nextQuestion.options.length ? nextQuestion.options.reduce((acc, val) => acc + parseInt(val.text.length), 0) : 0
        return optionsCount < 1000 ? 2000 : nextQuestion.message.length + optionsCount * 300
    }

    // добавляем данные в част из input c учетом возможного наличия auto_link_id
    async function addInputInChat() {
        const prevQuestion = questionList[questionList.length - 1]
        const payload = {
            id: null,
            auto_link_id: null,
            option_id: null,
            child_id: null,
            type: null,
            tag: null
        }
        if (prevQuestion.id) payload.id = prevQuestion.id
        if (prevQuestion.auto_link_id) payload.auto_link_id = prevQuestion.auto_link_id
        if (prevQuestion.type) payload.type = prevQuestion.type
        if (prevQuestion.tag) payload.tag = prevQuestion.tag
        setSuggestions([])
        const el = document.querySelector('.inp')
        if (!el) return
        const validator = await inputValidation(el.value)
        if (!validator) return
        // выводим ответ пользователя
        await setQuestionsList(q => [...q, {
            message: el.value,
            id: payload.id,
            auto_link_id: payload.auto_link_id,
            option_id: payload.option_id,
            child_id: payload.child_id,
            type: payload.type,
            tag: payload.tag
        }]) // user
        await scrollBox()
        if (!await addLastMessageOnChat()) return

        if (prevQuestion.auto_link_id) {
            await addAutoLinkID(prevQuestion)
            return
        }
        else {
            const nextQuestion = questions.find(q => q.idx === prevQuestion.idx + 1)
            const loaderMS = reduceCountDaley(nextQuestion)
            await loaderDelay(loaderMS)
            await setQuestionsList(q => [...q, nextQuestion]) // bot
            await scrollBox()
            await datePickerAndFocusInputInit(nextQuestion)
            localStorage.setItem('chat', JSON.stringify(questionList))
        }
    }

    // добавляем посты в чат из Options
    async function addQuestion(option, idx, id, auto_link_id, option_id, child_id, type, tag) {
        setSuggestions([])
        console.log('idx ', idx)
        // вывод сообщения пользователя взятое из кнопки option
        const optionMessage = option.text[0].toUpperCase() + option.text.slice(1)
        await setQuestionsList(q => [...q, {message: optionMessage, id, auto_link_id, option_id, child_id, type, tag }])
        await scrollBox()
        if (!await addLastMessageOnChat()) return

        // выводим последующее собщение от бота
        if (option.child_id) {
            const findNextQuestionOfOptionID = questions.filter(question => question.id === option.child_id)
            if (!findNextQuestionOfOptionID.length) return alert ('ERROR! Нет элемента совпадающего с option_id')
            const loaderMS = reduceCountDaley(findNextQuestionOfOptionID[0])
            await loaderDelay(loaderMS)
            await setQuestionsList(q => [...q, ...findNextQuestionOfOptionID])
            await scrollBox()
            await datePickerAndFocusInputInit(questions[idx])
            localStorage.setItem('chat', JSON.stringify(questionList))
            return
        }
        // смотрим нет ли у вопроса auto_link и если есть переходим к нужному вопросу
        const Parent = questionList.filter(q=>q.idx === idx)
        if (Parent.length && Parent[0].auto_link_id) {
            await addAutoLinkID(Parent[0])
            return
        }
        // если нет ни ссылки на option ни auto_link_id переходим к следующему в очереди элементу
        else {
            const nextQuestion = questions.filter(q=>q.idx === idx+1)
            if (!nextQuestion.length) return alert ('ERROR! Нет следующего по очереди элемента')
            const loaderMS = reduceCountDaley(nextQuestion[0])
            await loaderDelay(loaderMS)
            await setQuestionsList(q => [...q, ...nextQuestion])
            await scrollBox()
            await datePickerAndFocusInputInit(nextQuestion)
            localStorage.setItem('chat', JSON.stringify(questionList))
            return
        }
    }

    // добавляем если есть auto_link_id
    async function addAutoLinkID(Parent) {
        const nextAutoLinkQuestion = questions.filter(question => question.id === Parent.auto_link_id)
        if (!nextAutoLinkQuestion.length) return alert ('ERROR! Нет элемента с нужным auto_link_id')
        const loaderMS = reduceCountDaley(nextAutoLinkQuestion[0])
        await loaderDelay(loaderMS)
        await setQuestionsList(q => [...q, ...nextAutoLinkQuestion])
        await scrollBox()
        await datePickerAndFocusInputInit(nextAutoLinkQuestion)
        localStorage.setItem('chat', JSON.stringify(questionList))
    }

    // Удаляем ответ и последний вопрос
    async function deleteAnswer() {
        const arr = [1,2]
        let qArr = questionList
        if (!qArr.length || qArr.length < 2) return
        for (let x of arr) {
            qArr.pop()
        }
        await setQuestionsList([...qArr])
        await scrollBox()
        localStorage.setItem('chat', JSON.stringify(questionList))
        await datePickerAndFocusInputInit(questions[0])
    }

    function getLocalStorageState() {
        const localStorageState = localStorage.getItem('chat')
        if (!localStorageState) return false
        return JSON.parse(localStorageState)
    }

    useEffect(() => {
        const storageStatus = getLocalStorageState()
        storageStatus && storageStatus.length ? setQuestionsList(storageStatus) : setQuestionsList([questions[0]])
        datePickerAndFocusInputInit(questions[0])
    }, [questions])

    useEffect(() => {
        return () => localStorage.setItem('chat', JSON.stringify(questionList))
    }, []);

    return (
        <div className="relative">
            <Transition
                appear
                show={loader}
                as={Fragment}
                enter="transition-opacity ease-linear duration-400 transform"
                enterFrom="opacity-0"
                enterTo="transform opacity-100 scale-100"
                leave="transition-opacity ease-linear duration-200 transform"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0"
            >
                <div className="points__wrapper">
                    <div className="snippet" data-title=".dot-pulse">
                        <div className="stage">
                            <div className="dot-pulse"></div>
                            <p className="loader__points__text">печатает</p>
                        </div>
                    </div>
                </div>
            </Transition>

            <div className="mt-2 mb-2 w-full border border-gray-200 rounded p-2 h-[500px] overflow-auto shadow-black bg-gray-50">
                {
                    questionList.map((q, idx) => {
                            if (q && q.bot) return (
                                <div className="mb-2" key={idx} id={idx === questionList.length - 1 ? 'scroll' : idx}>
                                    <div
                                        className="bot__cloud bg-gray-100 p-4 inline-block min-w-[50%] max-w-[80%] bg-[#E5E5EA]">
                                        <p className="text-sm font-bold mb-4 text-[17px]">{q.message}</p>
                                        {
                                            (q.options && q.options.length) &&
                                            q.options.map((opt, i) => (
                                                <div
                                                    key={i}
                                                    onClick={() => idx === questionList.length - 1 && addQuestion(opt, q.idx, q.id, q.auto_link_id, opt.id, opt.child_id, q.type, q.tag)}
                                                    className={idx === questionList.length - 1 ?
                                                        "rounded-3xl text-[14px] text-center p-2 mb-2 mr-2 cursor-pointer border border-gray-300 bg-white hover:bg-[#0B93F6] hover:text-white ease-in duration-300 inline-block" :
                                                        "rounded-3xl text-[14px] text-center p-2 mb-2 mr-2 cursor-default disabled:bg-gray-300 bg-gray-300 inline-block"
                                                    }>
                                                    {opt.text}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                            else return (
                                <div className="mb-2 text-right relative" key={idx}
                                     id={idx === questionList.length - 1 ? 'scroll' : idx}>
                                    {
                                        !q.bot && idx === questionList.length - 2 &&
                                        <div
                                            className="absolute right-[-7px] top-[-14px] w-[20px] h-[20px] cursor-pointer"
                                            onClick={()=>deleteAnswer()}
                                        >
                                            <XCircleIcon className="text-red-400"/>
                                        </div>
                                    }
                                    <div
                                        className="user__cloud bg-gray-100 p-4 inline-block max-w-[80%] bg-[#0B93F6] text-left shadow-black">
                                        <p className="text-sm text-white font-bold text-[17px]">{q.message}</p>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>

            {questionList.length && questionList[questionList.length - 1].bot && questionList[questionList.length - 1].type && questionList[questionList.length - 1].type !== 'options' &&
                <div className="flex items-center relative">

                    <Transition
                        appear
                        show={error.status}
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-400 transform"
                        enterFrom="opacity-0"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition-opacity ease-linear duration-200 transform"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0"
                    >
                        <div className="fixed flex items-center z-50">
                            <div className="inline-block p-2 bg-red-500 text-white rounded">{ error.text }</div>
                        </div>
                    </Transition>

                    <div
                        className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:h-7 sm:w-7 mr-2 absolute left-2"
                        dangerouslySetInnerHTML={{__html: getIcon(questionList[questionList.length - 1].type)}}>
                    </div>

                    {
                        questionList[questionList.length - 1].type === 'text' &&
                        <input type="text"
                               className="w-full border border-gray-200 rounded pl-12 pt-2 pb-2 br-2 bg-blue-50 inp"
                               placeholder="Впишите свой ответ"
                               onKeyDown={event => event.keyCode === 13 && addInputInChat()}
                        />
                    }
                    {
                        questionList[questionList.length - 1].type === 'address' &&
                        <input type="text"
                               className="w-full border border-gray-200 rounded pl-12 pt-2 pb-2 br-2 bg-blue-50 inp"
                               placeholder="Впишите адрес"
                               onKeyDown={event => event.keyCode === 13 && addInputInChat()}
                               onChange={event => getSuggestions(event.target.value)}
                        />
                    }
                    {
                        questionList[questionList.length - 1].type === 'date' &&
                        <input
                            id="datepicker"
                            type="text"
                            className="datepicker w-full border border-gray-200 rounded pl-12 pt-2 pb-2 br-2 bg-blue-50 inp"
                            placeholder="Впишите дату"
                            onKeyDown={event => event.keyCode === 13 && addInputInChat()}
                        />
                    }
                    {
                        questionList[questionList.length - 1].type === 'name' &&
                        <input type="text"
                               className="w-full border border-gray-200 rounded pl-12 pt-2 pb-2 br-2 bg-blue-50 inp"
                               placeholder="Впишите имя"
                               onKeyDown={event => event.keyCode === 13 && addInputInChat()}
                               onChange={event => getSuggestions(event.target.value)}
                        />
                    }
                    {
                        questionList[questionList.length - 1].type === 'number' &&
                        <input type="text"
                               className="w-full border border-gray-200 rounded pl-12 pt-2 pb-2 br-2 bg-blue-50 inp"
                               placeholder="Впишите число"
                               onKeyDown={event => event.keyCode === 13 && addInputInChat()}
                        />
                    }
                    {
                        suggestions.length ?
                            <div
                                className="absolute bottom-11 left-0 z-50 inline-block rounded-lg bg-white dark:bg-gray-700 shadow-lg p-4">
                                {
                                    suggestions.map((s, i)=>(
                                        <div
                                            key={i}
                                            onClick={()=>addSuggestionOnInput(s.unrestricted_value)}
                                            className="cursor-pointer p-3  hover:bg-gray-200"
                                        >
                                            {s.unrestricted_value}
                                        </div>
                                    ))
                                }
                            </div>
                            : <div></div>
                    }
                    <PaperAirplaneIcon
                        className="ml-2 w-[25px] h-[25px] cursor-pointer text-blue-600"
                        onClick={() => addInputInChat()}
                    />
                </div>
            }

        </div>
    )
}