import {Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import InputQuestion from "./InputQuestion";
import InputOptions from "./InputOptions";
import {useDispatch} from 'react-redux'
import {addQuestion} from "../../store/question/questionSlice";
import {PlusSmallIcon} from "@heroicons/react/20/solid";
import AlertQuestion from "./AlertQuestion";

export default function ModalQuestion({open, setOpen, params}) {
    const optionState = () => {
        return {
            id: 'Op-' + Math.round(new Date() / 1000),
            child_id: null,
            text: ''
        }
    }
    const dispatch = useDispatch();
    const cancelButtonRef = useRef(null);
    const [question, setQuestion] = useState('');
    const [alertError, setAlertError] = useState('')
    const [options, setOptions] = useState([ optionState() ])

    // Добавляем данные из модалки в список хранящейся в DB
    const addQuestionToList = (obj) => {
        if (!obj.message) {
            setAlertError('Поле "Текст вопроса" не может быть пустым!')
            closeAlertError()
            return
        }
        if (checkEmptyOptionsField()) {
            setAlertError('Поле "Варианты ответов" не может быть пустым!')
            closeAlertError()
            return
        }
        if (params.type === 'options') obj.options = options
        setOpen(false)
        dispatch(addQuestion(obj))
        setOptions([optionState()])
        setQuestion('')
    }

    // Валидируем и добавляем options в модалке
    function addOption() {
        if (options.length && !options[options.length-1].text) {
            setAlertError('Сначала заполните последней вариант ответа!')
            closeAlertError()
            return
        }
        setOptions([
            ...options,
            optionState()
        ])
    }

    // Удаляем опцию по id
    const removeOption = (id) => {
        if (!id) return
        const newOptions = []
        for (let item of options) {
            if (item.id !== id) {
                newOptions.push(item)
            }
        }
        setOptions(newOptions)
    }

    // Вставляем тест в опцию
    const optionText = (params) => {
        const {id, text} = params
        const indexOption = options.findIndex(item=>item.id === id)
        options[indexOption].text = text
        setOptions(options)
    }

    // Закрываем модалку и вычищаем поля
    const closeModal = () => {
        setOpen(false)
        setQuestion('')
        setOptions([optionState()])
    }

    // Следим за закрытием модалки и вычищаем опции
    if (open === false && options.length) {
        options.forEach(item=>{
            if (item.text) {
                setOptions([optionState()])
            }
        })
    }

    // Закрываем сообщение об ошибки через установленный промежуток времени
    function closeAlertError() {
        setTimeout(()=> setAlertError(''), 3000)
    }

    // Проверяем опции на пустые поля
    function checkEmptyOptionsField() {
        if (!options.length || params.type !== 'options') return
        let noEmpty = false
        for (let item of options) {
            if (!item.text) {
                noEmpty = true
                break
            }
        }
        return noEmpty
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <params.icon className="h-6 w-6 text-blue-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                            {
                                                alertError && <AlertQuestion text={alertError}/>
                                            }
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                Тип: {params.name}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {params.description}
                                                </p>
                                            </div>
                                            <div className="divider"></div>
                                            <div>
                                                <InputQuestion setQuestion={setQuestion}/>
                                            </div>
                                            {
                                                params.type === 'options' && (
                                                    <div>
                                                        <div className="divider"></div>
                                                        <div className="flex justify-between items-center mb-4">
                                                            <div className="block text-sm font-medium text-gray-700">
                                                                Впишите варианты ответов:
                                                            </div>
                                                            <div
                                                                className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 hover:bg-blue-200 sm:mx-0 sm:h-7 sm:w-7 cursor-pointer">
                                                                <PlusSmallIcon
                                                                    onClick={ () => addOption() }
                                                                    className="h-4 w-4 text-blue-600" aria-hidden="true"/>
                                                            </div>
                                                        </div>
                                                        {
                                                            options.map((item, i) => (
                                                                <InputOptions
                                                                    key={i}
                                                                    minusIdx={i === 0 ? false : true}
                                                                    remove={removeOption} id={item.id}
                                                                    optionText={optionText}
                                                                    text={item.text}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            addQuestionToList({
                                                id: Math.round(new Date() / 1000),
                                                bot: true,
                                                message: question,
                                                type: params.type,
                                                auto_link_id: null
                                            })
                                        }}
                                    >
                                        Добавить
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => closeModal()}
                                        ref={cancelButtonRef}
                                    >
                                        Отмена
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
