import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import updateTechnicalIssues from "../../tools-scripts/updateTechnicalIssues";

export default function SlideOvers({appeal, openSlideOver, setOpenSlideOver}) {

    const [checkedState, setCheckedState] = useState(appeal.technical_issues);
    const handleOnChange = (ev, position) => {
        let newTechIssues = JSON.parse(JSON.stringify(appeal.technical_issues))
        const NewTechnicalIssues = newTechIssues.map((item, index) => {
                if (index === position) item.status = ev.target.checked
                else item.status = checkedState[index].status
                return item
            }
        )
        setCheckedState(NewTechnicalIssues)
        updateTechnicalIssues(appeal._id, NewTechnicalIssues)
    }

    return (
        <>
            <Transition.Root show={openSlideOver} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setOpenSlideOver}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-in-out duration-500"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in-out duration-500"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div
                                                className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                                                <button
                                                    type="button"
                                                    className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                    onClick={() => setOpenSlideOver(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </Transition.Child>
                                        <div
                                            className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">Технические
                                                    вопросы</Dialog.Title>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                {/* Replace with your content */}
                                                <div className="absolute inset-0 px-4 sm:px-6">
                                                    <div
                                                        className="h-full border-2 border-dashed border-gray-200 p-2"
                                                        aria-hidden="true">
                                                        {
                                                            appeal && appeal?.technical_issues.length ?
                                                                (
                                                                    appeal.technical_issues.map((item, i) => {
                                                                        return (
                                                                            <div key={i}
                                                                                 className="border-b pt-2 pb-2">
                                                                                <div
                                                                                    className="flex items-center justify-between">
                                                                                    <div>
                                                                                        <div
                                                                                            className="font-bold">{item.text}</div>
                                                                                        {item.comment &&
                                                                                            <>
                                                                                                <div
                                                                                                    className="font-medium: text-[13px] pt-2">Коментарий:
                                                                                                </div>
                                                                                                <div
                                                                                                    className="font-light text-[13px]">{item.comment}</div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <input className="ml-4"
                                                                                           type="checkbox"
                                                                                           checked={checkedState[i].status}
                                                                                           onChange={(ev) => handleOnChange(ev, i)}/>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })
                                                                )
                                                                :
                                                                (
                                                                    <div>Нет данных</div>
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                                {/* /End replace */}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}