import {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {addQuestionStatusFunc, replaceQuestion, setPopoverStatus} from "../store/question/questionSlice";
import {XMarkIcon} from "@heroicons/react/20/solid";
import SlideOvers from "../components/builder/SlideOvers";

import BindChildModal from "../components/modals/BindChildModal";
import EditChildModal from "../components/modals/EditChildModal";
import EditQuestionModal from "../components/modals/EditQuestionModal";
import RemoveQuestionModal from "../components/modals/RemoveQuestionModal";
import AddOptionOnQuestionModal from "../components/modals/AddOptionOnQuestionModal";
import AddToolsLabel from "../components/modals/add-tools-label/AddToolsLabel";
import AppealInf from "../components/AppealInf";

import QuestionsList from "../components/builder/QuestionsList";
import Appeals from "../components/Appeals";

const Home = () => {
    const dispatch = useDispatch();
    let {questions, appeals, appealsToggle, appeal} = useSelector(state => state);

    const [autoLinkCheck, setAutoLinkCheck] = useState({
        parent_id: null,
        child_id: null
    })
    const [questionCheck, setQuestionCheck] = useState({
        parent_id: null,
        option_id: null,
        child_id: null
    })
    const [optionCheck, setOptionCheck] = useState();
    const [PID, setPID] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenChildModal, setIsOPenChildModal] = useState(false);
    const [isOpenQuestionModal, setIsOpenQuestionModal] = useState(false);
    const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
    const [modalQuestions, setModalQuestions] = useState([]);
    const [isOpenAddOptionModal, setIsOpenAddOptionModal] = useState(false)
    const [isOpenAddLabel, setIsOpenAddLabel] = useState(false)
    const [openSlideOver, setOpenSlideOver] = useState(false)
    const [toolsParam, setToolsParam] = useState(null)

    function closeModal() {
        setIsOpen(false)
        //setOptionCheck(null)
        //toggleOption(optionCheck, PID, questions)
    }

    function openModal() {
        setIsOpen(true);
    }

    function openChildModal() {
        setIsOPenChildModal(true)
    }

    function closeChildModal() {
        setIsOPenChildModal(false)
    }

    function openQuestionEditModal() {
        setIsOpenQuestionModal(true)
    }

    function closeQuestionEditModal() {
        setIsOpenQuestionModal(false)
    }

    function openAddOptionModal() {
        setIsOpenAddOptionModal(true)
    }

    function closeAddOptionModal() {
        setIsOpenAddOptionModal(false)
    }

    function openRemoveModal() {
        setIsOpenRemoveModal(true)
    }

    function closeRemoveModal() {
        setIsOpenRemoveModal(false)
    }

    function openAddLabelModal() {
        setIsOpenAddLabel(true)
    }

    function closeLabelModal() {
        setIsOpenAddLabel(false)
    }

    const questionConfig = [
        {
            label: 'Отменить перенаправление',
            eventClickBind: async (parentID) => {
                await removeChildBind(null, parentID)
            }
        },
        {
            label: 'Перенаправить',
            eventClickBind: async (parentID) => {
                await childBind(null, parentID)
            }
        },
        {
            label: 'Добавить вопрос ↑',
            eventClickBind: async (parentID) => {
                if (!parentID) return
                dispatch(addQuestionStatusFunc({
                    up: true,
                    parentID
                }))
            }
        },
        {
            label: 'Добавить вопрос ↓',
            eventClickBind: async (parentID) => {
                if (!parentID) return
                dispatch(addQuestionStatusFunc({
                    up: false,
                    parentID
                }))
            }
        },
        {
            label: 'Редактировать вопрос',
            eventClickBind: async (parentID) => {
                await editQuestionName(parentID)
            }
        },
        {
            label: 'Добавить вариант ответа',
            eventClickBind: async (parentID) => {
                await addOptionOnQuestion(parentID)
            },
            option: true,
        },
        {
            label: 'Удалить',
            type: 'Remove',
            eventClickBind: async (parentID) => {
                await removeQuestionOrOption(null, parentID)
            }
        },
        //------------------------------------------------------------------- TOOLS -----
        {
            name: 'applicant',
            label: 'Истец (Заявитель)',
            eventClickBind: async (parentID) => {
                await toolsBind(parentID, 'applicant', 'Истец (Заявитель)')
            }
        },
        {
            name: 'defendant',
            label: 'Ответчик',
            eventClickBind: async (parentID) => {
                await toolsBind(parentID, 'defendant', 'Ответчик')
            }
        },
        {
            name: 'amount',
            label: 'Сумма иска',
            eventClickBind: async (parentID) => {
                await toolsBind(parentID, 'amount', 'Сумма иска')
            }
        },
        {
            name: 'podsud_rod',
            label: 'Родовая подсудность',
            eventClickBind: async (parentID) => {
                await toolsBind(parentID, 'podsud_rod', 'Родовая подсудность')
            }
        },
        {
            name: 'podsud_terr',
            label: 'Сторона подсудности',
            eventClickBind: async (parentID) => {
                await toolsBind(parentID, 'podsud_terr', 'Сторона подсудности')
            }
        },
        {
            label: 'Отвязать инструмент',
            eventClickBind: async (parentID) => {
                await toolsBind(parentID, null, 'Отвязать инструмент')
            }
        }
    ];

    const optionsConfig = [
        {
            label: 'Отменить перенаправление',
            eventClickBind: async (id, parentID) => {
                await removeChildBind(id, parentID)
            }
        },
        {
            label: 'Перенаправить',
            eventClickBind: async (id, parentID) => {
                await childBind(id, parentID)
            }
        },
        {
            label: 'Редактировать вариант ответа',
            eventClickBind: async (id, parentID) => {
                await editChildName(id, parentID)
            }
        },
        {
            label: 'Удалить',
            eventClickBind: async (id, parentID) => {
                await removeQuestionOrOption(id, parentID)
            }
        }
    ];

    function questionsModify(id, parenID) {
        let activeParentElementIDX = questions.findIndex(itm => itm.id === parenID);
        if (!questions.length) return
        const newQuestionForModify = JSON.parse(JSON.stringify(questions));
        let questionsModify = newQuestionForModify.filter((_, i) => i >= activeParentElementIDX);
        closeBindLine()
        setModalQuestions(questionsModify);
    }

    async function removeChildBind(id, parentID) {
        console.log({id, parentID})
        if (!parentID) return
        const questionsRCNB = JSON.parse(JSON.stringify(questions))
        const parent = questionsRCNB.find(q => q.id === +parentID)
        if (!id && parent) {
            parent.auto_link_id = null
            await dispatch(replaceQuestion(questionsRCNB))
            clearCheckAutoLink()
        }
        else if (id && parent) {
            const option = parent.options.find(o=>o.id === id)
            if (option) {
                option.child_id = null
                await dispatch(replaceQuestion(questionsRCNB))
            }
            closeBindLine()
        }
        else console.error('removeChildBind not correctly params...')
    }

    async function childBind(id, parenID) {
        setOptionCheck(id);
        setPID(parenID);
        questionsModify(id, parenID);
        closeBindLine()
        await openModal();
    }

    async function editChildName(id, parentID) {
        setOptionCheck(id);
        setPID(parentID);
        closeBindLine()
        await openChildModal()
    }

    async function editQuestionName(parentID) {
        setPID(parentID);
        closeBindLine()
        await openQuestionEditModal()
    }

    async function addOptionOnQuestion(parentID) {
        setPID(parentID)
        closeBindLine()
        await openAddOptionModal()
    }

    async function removeQuestionOrOption(id, parentID) {
        if (id) {
            const qIDX = questions.findIndex(q => q.id === parentID)
            if (qIDX !== -1) {
                if (questions[qIDX].options.length === 1) {
                    alert('Вы не можете удалить единственный ответ!')
                    return
                }
            } else return
        }
        setOptionCheck(id);
        setPID(parentID);
        closeBindLine()
        openRemoveModal()
    }

    async function toolsBind(parentID, name = null, label) {
        if (!name && !label) await addTag(parentID, name, label)
        if (name === 'podsud_rod' || name === 'podsud_terr') {
            setToolsParam({parentID, name, label})
            setIsOpenAddLabel(true)
        } else await addTag(parentID, name, label)
    }

    async function addTag(parentID, name, label, keyword = null) {
        let questionsTmp = JSON.parse(JSON.stringify(questions));
        let parentQuestionIDX = questions.findIndex(item => item.id === parentID);
        questionsTmp[parentQuestionIDX].tag = name && label ? {name, label, keyword} : null
        await dispatch(replaceQuestion(questionsTmp));
    }

    async function addAutoLink(selectType) {
        let questionsTmp = JSON.parse(JSON.stringify(questions));
        let parentQuestionIDX = questions.findIndex(item => item.id === PID);
        questionsTmp[parentQuestionIDX].auto_link_id = selectType.id
        checkAutoLink(PID, selectType.id)
        await dispatch(replaceQuestion(questionsTmp));
    }

    function checkAutoLink(parent_id, child_id) {
        if (!parent_id || !child_id) return clearCheckAutoLink()
        setAutoLinkCheck({
            parent_id,
            child_id
        })
        boxLineBuilder({parent_id, option_id: null, child_id})
    }

    function clearCheckAutoLink() {
        setAutoLinkCheck({
            parent_id: null,
            child_id: null
        })
        let attr = document.getElementById('box-auto_line')
        if (attr) attr.removeAttribute('style')
        boxLineBuilder({parent_id: null, option_id: null, child_id: null})
    }

    async function addBindHandler(selectType) {
        let questionsTmp = JSON.parse(JSON.stringify(questions));
        let parentQuestion = questions.find(item => item.id === PID);
        let parentQuestionIDX = questions.findIndex(item => item.id === PID);

        async function getOptions() {
            if (!parentQuestion || parentQuestion === undefined || parentQuestionIDX === -1) return
            if (parentQuestion.type === 'options' && parentQuestion.options.length) {
                const parentOptions = parentQuestion.options.find(opt => opt.id === optionCheck);
                const parentOptionsIDX = parentQuestion.options.findIndex(opt => opt.id === optionCheck);
                return {parentOptions, parentOptionsIDX}
            } else return {parentOptions: null, parentOptionsIDX: null}
        }

        const getQuestionResult = await getOptions()

        if (getQuestionResult.parentOptions) questionsTmp[parentQuestionIDX].options[getQuestionResult.parentOptionsIDX].child_id = selectType.id
        else questionsTmp[parentQuestionIDX].auto_link_id = selectType.id

        dispatch(replaceQuestion(questionsTmp));

        toggleOption(optionCheck, PID, questionsTmp)
    }

    function toggleOption(option_id, parent_id, quest) {
        if (!option_id) return;
        const parent = quest.find(itm => itm.id === parent_id);

        if (!parent || !parent.options.length) return;
        const opt = parent.options.find(itm => itm.id === option_id);

        if (!opt.child_id) closeBindLine()
        else {
            setQuestionCheck({parent_id, option_id, child_id: opt.child_id})
            boxLineBuilder({parent_id, option_id, child_id: opt.child_id})
        }
    }

    function closeBindLine() {
        setQuestionCheck({
            parent_id: null,
            option_id: null,
            child_id: null
        })
        let attr = document.getElementById('box-line')
        if (attr) attr.removeAttribute('style')
        boxLineBuilder({parent_id: null, option_id: null, child_id: null})
    }

    function boxLineBuilder(payload) {
        const $ = findDOMElements(payload)
        if (!payload.parent_id || !payload.child_id) return

        const childElementRects = $.childElement.getClientRects()[0]
        const childXY = getOffset($.childElement)

        if ($.optionElement) {
            $.boxLine.removeAttribute("style")
            const optionElementRects = $.optionElement.getClientRects()[0]
            const optionXY = getOffset($.optionElement)
            const topOption = optionXY.y + optionElementRects.height / 2
            const heightBox = (childXY.y + childElementRects.height / 2) - (optionXY.y + optionElementRects.height / 2)
            $.boxLine.style.cssText += `display: block; top: ${topOption - 100}px; right: ${optionXY.x - 45}px; height: ${heightBox}px`;
        } else if (payload.child_id) {
            $.boxAutoLine.removeAttribute("style")
            const parentElementRects = $.parentElement.getClientRects()[0]
            const parentXY = getOffset($.parentElement)
            const topParent = parentXY.y + parentElementRects.height / 2
            const heightBox = (childXY.y + childElementRects.height / 2) - (parentXY.y + parentElementRects.height / 2)
            $.boxAutoLine.style.cssText += `display: block; top: ${topParent - 100}px; right: ${parentXY.x - 42}px; height: ${heightBox}px`;
        }
    }

    function findDOMElements(payload) {
        const parentElement = document.getElementById(payload.parent_id)
        const optionElement = payload.option_id ? document.getElementById(payload.option_id) : null
        const childElement = document.getElementById(payload.child_id)
        const boxLine = document.getElementById('box-line')
        const boxAutoLine = document.getElementById('box-auto_line')
        return {parentElement, optionElement, boxLine, childElement, boxAutoLine}
    }

    function getOffset(el) {
        let _x = 0;
        let _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return {x: _x, y: _y};
    }

    function lineBuildResizeHandler() {
        window.addEventListener('resize', () => {
            closeBindLine()
            clearCheckAutoLink()
        })
    }

    async function onPopover() {
        await dispatch(setPopoverStatus(false))
        await dispatch(setPopoverStatus(true))
    }

    useEffect(() => {
        setOptionCheck(null);
        setPID((null));
        setModalQuestions(questions);
        lineBuildResizeHandler()
        if (appealsToggle) {
            closeBindLine()
            clearCheckAutoLink()
        }
    }, [questions, appealsToggle])

    if (appealsToggle && !appeals.length) {
        return (
            <div className="questions__list__block pb-[40%] relative">
                <div>Загрузка...</div>
            </div>
        )
    }

    return (
        <div className="questions__list__block pb-[40%] relative">
            {appeal && appeal.technical_issues && appeal.technical_issues.length ?
                <SlideOvers appeal={appeal} openSlideOver={openSlideOver} setOpenSlideOver={setOpenSlideOver}/> : ''}

            <BindChildModal isOpen={isOpen} closeModal={closeModal} id={optionCheck} pid={PID}
                            questions={modalQuestions} addBindHandler={addBindHandler} addAutoLink={addAutoLink}/>

            <EditChildModal isOpen={isOpenChildModal} closeModal={closeChildModal} pid={PID} id={optionCheck}
                            questions={questions}/>

            <EditQuestionModal isOpen={isOpenQuestionModal} closeModal={closeQuestionEditModal} pid={PID}
                               questions={questions}/>

            <AddOptionOnQuestionModal isOpen={isOpenAddOptionModal} closeModal={closeAddOptionModal} pid={PID}
                                      questions={questions}/>

            {appeal && appeal.technical_issues.length ?
                <AddToolsLabel isOpen={isOpenAddLabel} closeModal={closeLabelModal} addTag={addTag}
                               setToolsParam={setToolsParam} toolsParam={toolsParam} appeal={appeal}/> : ''}

            <RemoveQuestionModal isOpen={isOpenRemoveModal} closeModal={closeRemoveModal} id={optionCheck} pid={PID}
                                 questions={questions}/>

            <div id="box-line" className="box__line">
                <div className="box__line__close" onClick={() => closeBindLine()}>
                    <XMarkIcon/>
                </div>
            </div>

            <div id="box-auto_line" className="box__auto__line">
                <div className="box__line__close" onClick={() => clearCheckAutoLink()}>
                    <XMarkIcon/>
                </div>
            </div>

            {appealsToggle && <Appeals appeals={appeals} appeal={appeal}/>}

            {!appealsToggle && questions.length && appeal ?
                <AppealInf appeal={appeal} openSlideOver={openSlideOver} setOpenSlideOver={setOpenSlideOver}/> : ''}

            {!appealsToggle && questions.length ? <div>
                <div className="flex flex-col text-center">
                    <QuestionsList
                        questions={questions}
                        toggleOption={toggleOption}
                        optionCheck={optionCheck}
                        questionCheck={questionCheck}
                        optionsConfig={optionsConfig}
                        questionConfig={questionConfig}
                        checkAutoLink={checkAutoLink}
                        autoLinkCheck={autoLinkCheck}
                    />
                </div>
            </div> : ''}

            {
                !appealsToggle && !questions.length ?
                    <>
                        {appeal && <AppealInf appeal={appeal} openSlideOver={openSlideOver}
                                              setOpenSlideOver={setOpenSlideOver}/>}
                        <div className="p-5 rounded bg-gray-100 text-center">
                            <h2 className="font-bold mb-5">Нет данных для отображения списка вопросов!</h2>
                            <h2
                                className="inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-300 bg-green-200 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-green-500 hover:text-white sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => onPopover()}
                            >
                                Создайте первый вопрос!
                            </h2>
                        </div>
                    </>
                    : ''
            }

        </div>
    );
};

export default Home;