import getIcon from "../../tools-scripts/getIcon";
import Dropdown from "../create-question/Dropdown";
import typePayload from "../../tools-scripts/typePayload";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function QuestionsList({
                                          questions,
                                          optionCheck,
                                          toggleOption,
                                          questionCheck,
                                          optionsConfig,
                                          questionConfig,
                                          checkAutoLink,
                                          autoLinkCheck
                                      }) {

    function getQuestionLinkStatus(item) {
        const questionsLinkIndex = questions.findIndex(itm => itm.id === item.auto_link_id)
        if (questionsLinkIndex) return questionsLinkIndex + 1
    }

    function getOptionsLinkStatus(id) {
        const optionsLinkIndex = questions.findIndex(itm => itm.id === id)
        if (optionsLinkIndex) return optionsLinkIndex + 1
    }

    return (
        questions.map((item, i) => {
            return <div
                key={i}
                className={autoLinkCheck.parent_id === item.id || autoLinkCheck.child_id === item.id ? 'bg-gray-100 hover:shadow sm:rounded-lg inline-block py-3 px-5 min-w-[400px] w-[600px] ring-2 ring-blue-500 ring-offset-2 ring-offset-gray-100 margin__auto relative' : questionCheck.parent_id && questionCheck.child_id === item.id ?
                    'bg-gray-100 hover:shadow sm:rounded-lg inline-block py-3 px-5 min-w-[400px] w-[600px] ring-2 ring-green-500 ring-offset-2 ring-offset-gray-100 margin__auto relative' :
                    'bg-gray-100 hover:shadow sm:rounded-lg inline-block py-3 px-5 min-w-[400px] w-[600px] margin__auto relative'
                }
                id={item.id}
                onClick={() => checkAutoLink(item.id, item.auto_link_id || null)}
            >
                <div className="text-xs text-gray-500 flex justify-center relative">
                    {item.tag ? <div
                        className="absolute left-[-35px] top-[-20px] text-[10px] bg-red-100 rounded-lg p-2 font-bold">{item.tag.label}</div> : ''}
                    <p className="label__circle">
                        <span>{item.idx}</span>
                        {item.auto_link_id ? <span className="flex items-center">
                            <FontAwesomeIcon icon={faArrowRight} className="pl-2 pr-2"/>
                            <span>{getQuestionLinkStatus(item)}</span>
                        </span> : ''}
                    </p>
                </div>
                <div className="flex justify-between">
                    <div className="flex items-center">
                        <div
                            className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-7 sm:w-7"
                            dangerouslySetInnerHTML={{__html: getIcon(item.type)}}
                        />
                        <p className="ml-2 font-bold text-xs text-gray-500">{typePayload(item.type).name}</p>
                    </div>
                    <Dropdown config={questionConfig} type={item.type} id={item.id} tag={item.tag} childID={item.auto_link_id}/>
                </div>
                <div className="divider"></div>
                <p className="text-center">{item.message}</p>
                {
                    item.type === 'options' && (
                        <div>
                            <div className="divider"></div>
                            {
                                item.options.map(option => (
                                        <div key={option.id}
                                             onClick={() => toggleOption(option.id, item.id, questions)}
                                             id={option.id}
                                             className={optionCheck === option.id ? 'text-center py-2 px-5 rounded bg-white mb-2 text-sm relative ring-2 ring-green-500 ring-offset-2 ring-offset-gray-100 cursor-pointer' : questionCheck.option_id && questionCheck.option_id === option.id ? 'text-center py-2 px-5 rounded bg-white mb-2 text-sm relative ring-2 ring-green-500 ring-offset-2 ring-offset-gray-100 cursor-pointer' : 'text-center py-2 px-5 rounded bg-white mb-2 text-sm relative cursor-pointer'}>
                                            {
                                                option.child_id ?
                                                    <div className="absolute left-3 top-[10px] text-xs text-gray-500">
                                                        <span>{item.idx}</span>
                                                        <FontAwesomeIcon icon={faArrowRight} className="pl-2 pr-2"/>
                                                        <span>{getOptionsLinkStatus(option.child_id)}</span>
                                                    </div>
                                                    : ''
                                            }
                                            <div>{option.text}</div>
                                            <div className="absolute right-1 top-[10px]">
                                                <Dropdown config={optionsConfig} id={option.id} parentID={item.id} childID={option.child_id}/>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    )
                }
            </div>
        })
    )
}