import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import axios from "axios";
import {saveAs} from 'file-saver'
import getIcon from "../../tools-scripts/getIcon";

export default function RocketTextModal({closeRocketModal, isRocketModalOpen, textTMP, resultText, appeal}) {

    const [text, setText] = useState('')
    const [appealText, setAppealText] = useState([])
    const [Chat, setChat] = useState(localStorage.getItem('chat') ? JSON.parse(localStorage.getItem('chat')) : [])
    const [court, setCourt] = useState(null)
    const [textRes, setTextRes] = useState('')
    const [feetax, setFeetax] = useState({fee: null, tax: null})

    const DataJSON = [{
        "document_type": "sud_appeal",
        "sud_title":  '',
        "sud_address": '',
        "participants": [
            {
                type: "applicant",
                "status_title": "Истец",
                "name": "",
                "address": ""
            },
            {
                type: "defendant",
                "status_title": "Ответчик",
                "name": "",
                "address": ""
            }
        ],
        "price": null,
        "tax": null,
        "document_title": "ИСКОВОЕ ЗАЯВЛЕНИЕ",
        "document_subtitle": "о расторжении брака",
        "appeal_text": appealText,
        "requesting": {
            "request_title": "ПРОШУ СУД:",
            "request_text": [textRes.replace(/( |<([^>]+)>)/ig, ' ')]
        },
        "apps": {
            "apps_title": "Приложения:",
            "apps_list": [
                "Копии искового заявления.",
                "Копия свидетельства о расторжении брака.",
                "Копия свидетельства о рождении ребенка"
            ]
        },
        "requisites": {
            "representatives": [],
            "signatories": [
                {
                    "status": true,
                    "name": "",
                    "signature_base64": "",
                    "stamp_base64": ""
                }
            ]
        }
    }]

    async function Rocket(type = 'textTMP') {
        const textArray = []
        let joinText = null

        if (type === 'textTMP') {
            for (let txt of textTMP) {
                if (!txt.id) textArray.push(txt.text)
                else {
                    let Quest = Chat.find(itm => !itm.bot && itm.id === txt.id)
                    let questSome = Quest && Quest.option_id && txt.option_id === Quest.option_id ? true : false
                    if (questSome) textArray.push(txt.text)
                }
            }

            // Объединяем все для формирования теста для модалки
            joinText = textArray.join('<br>')
        }

        if (type === 'result') joinText = resultText.text

        let valueHTMLParse = new DOMParser().parseFromString(joinText, "text/html")
        if (valueHTMLParse && Chat && Chat.length) {
            const inputs = valueHTMLParse.querySelectorAll('input')
            if (Chat.length && inputs && inputs.length) {
                for (let inp of inputs) {
                    let message = null
                    for (let item of Chat) {
                        if (!item.bot && +item.id === +inp.dataset.id) message = item
                    }
                    if (message && message.type !== "options") {
                        let par = valueHTMLParse.createElement('p')
                        par.innerText = ` ${message.message} `
                        inp.insertAdjacentElement('beforebegin', par)
                    }
                }
            }
            const remInp = valueHTMLParse.querySelectorAll('input')
            remInp.forEach(inp => inp.remove())
            return valueHTMLParse
        }
    }

    async function getRocketTest(type = 'textTMP') {
        const localStorageChat = localStorage.getItem('chat') ? JSON.parse(localStorage.getItem('chat')) : []
        setChat(localStorageChat)
        const textRock = await Rocket(type) || null
        if (!textRock) return
        const TEXT = await replaceText(textRock)

        if (type === 'textTMP') {
            await appealTextCreator(TEXT.split('<br>'))
            setText(await replaceTagP(TEXT))
        }
        if (type === 'result') {
            let txt = await replaceTagP(TEXT)
            setTextRes(txt.replace(/ +/g, ' '))
        }
    }

    async function replaceText(textRock) {
        const Body = textRock.querySelector('body')
        return  Body.innerHTML.replace(/&nbsp;/g, ' ').replace(/\s{2,}/g, ' ')
    }

    async function replaceTagP(TEXT) {
        let TEXT_TEXT = JSON.stringify(TEXT)
        TEXT_TEXT = TEXT_TEXT
            .replace(/<p>/gi, '<span style="font-weight: bold">')
            .replace(/<\/p>/gi, '</span>')
        if (TEXT_TEXT[0] === '"') TEXT_TEXT = TEXT_TEXT.slice(1)
        if (TEXT_TEXT[TEXT_TEXT.length - 1] === '"') TEXT_TEXT = TEXT_TEXT.substring(0, +TEXT_TEXT.length - 1)
        return TEXT_TEXT
    }

    async function appealTextCreator(TEXT_ARR) {
        if (TEXT_ARR.length) {
            const NEW_TEXT_ARR = []
            for (let item of TEXT_ARR) {
                let replaceItem = item.replace(/( |<([^>]+)>)/ig, ' ')
                NEW_TEXT_ARR.push(replaceItem.replace(/ +/g, ' '))
            }
            setAppealText(NEW_TEXT_ARR)
        }
    }

    async function getCourt() {
        let addressApplicant = Chat.find(item => !item.bot && item.type === "address" && item.tag && item.tag.name === "applicant")
        if (!addressApplicant) return
        let address = addressApplicant.message
        if (address && !court) {
            let podsud = await axios.get(`https://api.территориальная-подсудность.рф/api?token=knVFnfW7ept1OSpY-GYeTC28qccxOVP9nwachyzLF00&address=${address}`)
            if (podsud.status === 200) {
                setCourt(podsud.data[0].request.court_fs)
            }
        }
    }

    function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    async function getFeeTax() {
        if (!Chat.length) return
        const feeTax = { fee: null, tax: null }
        for (let item of Chat) {
            if (!item.bot && item.type === "number" && item.tag && item.tag.name === "amount") feeTax.fee = item.message
        }
        if (!feeTax.fee) return feeTax
        let fee = {
            proceeding: appeal.proizv,
            appeal: appeal.title,
            imneim: +appeal.imneim,
            fizyur: +appeal.fizyur,
            cost: +feeTax.fee
        }
        const TAX = await getTax(fee)
        if (TAX) feeTax.tax = TAX
        return feeTax
    }

    async function getTax(fee) {
        if (!fee) return
        try {
            let feeRes = await axios('https://tools.территориальная-подсудность.рф/fee', {
                method: 'post',
                data: fee
            })
            return feeRes.data.fee
        }
        catch (err) {
            return console.error('getTax ', err)
        }
    }

    async function dataJsonMutation() {
        if (!Chat.length) return
        Chat.map((item, i) => {
            if (!item.bot && item.type === "name" && item.tag && item.tag.name === "applicant") {
                DataJSON[0].participants[0].name = DataJSON[0].requisites.signatories[0].name = item.message
            }
            if (!item.bot && item.type === "address" && item.tag && item.tag.name === "applicant") DataJSON[0].participants[0].address = item.message
            if (!item.bot && item.type === "name" && item.tag && item.tag.name === "defendant") DataJSON[0].participants[1].name = item.message
            if (!item.bot && item.type === "address" && item.tag && item.tag.name === "defendant") DataJSON[0].participants[1].address = item.message
        })
        DataJSON[0].sud_title = court.title
        DataJSON[0].sud_address = court.address
        DataJSON[0].document_title = appeal.type
        DataJSON[0].subtitle_title = appeal.name
        const FEE_TAX = await getFeeTax()
        if (FEE_TAX.fee) DataJSON[0].price = FEE_TAX.fee
        if (FEE_TAX.tax) DataJSON[0].tax = FEE_TAX.tax
    }

    async function getDoc() {
        await dataJsonMutation()
        const url = 'https://tools.территориальная-подсудность.рф/docx';
        const res = await axios.post(url, {DataJSON})
        if (res.status === 201) {
            const contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            const blob = await b64toBlob(res.data, contentType)
            await saveAs(blob, 'appeal.docx')
        } else alert('[ERROR] Ошибка при получении сформированного документа!')
    }

    useEffect(() => {
        if (isRocketModalOpen) {
            getRocketTest()
            getRocketTest('result')
        }
    }, [isRocketModalOpen])

    useEffect(() => {
        (async function () {
            if (isRocketModalOpen) await getCourt()
        })()
    }, [Chat, isRocketModalOpen, textTMP])

    useEffect(() => {
        (async function (){
            const TAX_FEE = await getFeeTax()
            setFeetax(TAX_FEE)
        })()
    }, [isRocketModalOpen, appeal, Chat])

    return (
        <>
            <Transition appear show={isRocketModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeRocketModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex justify-center p-4 mt-4  text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-bold leading-6 text-green-600 mb-2"
                                    >
                                        Пример текстового содержания заявления
                                    </Dialog.Title>

                                    <div className="w-full border border-b-[1px] bg-green-200 mt-6 mb-6"></div>
                                    {Chat.length ? <div>
                                        {court ? <div>
                                            <div className="font-bold">{court.title}</div>
                                            <div>{court.address}</div>
                                            <div>{court.email}</div>
                                            <div>{court.site}</div>
                                            <div>{court.tel}</div>
                                        </div> : ''}

                                        {Chat.map((item, i) => (
                                            !item.bot && item.type === "name" && item.tag && item.tag.name === "applicant" ?
                                                <div key={i} className="mt-3"><b>{item.tag.label}:</b> {item.message}
                                                </div> : ''
                                        ))}
                                        {Chat.map((item, i) => (
                                            !item.bot && item.type === "address" && item.tag && item.tag.name === "applicant" ?
                                                <div key={i}>{item.message}</div> : ''
                                        ))}

                                        {Chat.map((item, i) => (
                                            !item.bot && item.type === "name" && item.tag && item.tag.name === "defendant" ?
                                                <div key={i} className="mt-3"><b>{item.tag.label}:</b> {item.message}
                                                </div> : ''
                                        ))}
                                        {Chat.map((item, i) => (
                                            !item.bot && item.type === "address" && item.tag && item.tag.name === "defendant" ?
                                                <div key={i}>{item.message}</div> : ''
                                        ))}

                                        {feetax.fee ? <div className="mt-3"><b>Сумма иска:</b> {feetax.fee} рублей</div> : ''}
                                        {feetax.tax ? <div className="mt-3"><b>Госпошлина:</b> {feetax.tax} рублей</div> : ''}

                                        <div className="w-full border border-b-[1px] bg-green-200 mt-6 mb-6"></div>
                                    </div> : ''}


                                    {!Chat.length ?
                                        <div className="mt-2 uppercase text-red-500">Сначала сформируйте чат!</div>
                                        :
                                        !text ?
                                            <div className="mt-2 uppercase text-red-500">Нет данных для отображения!</div>
                                            :
                                            <div className="mt-2" dangerouslySetInnerHTML={{__html: text}}></div>
                                    }

                                    <div className="w-full border border-b-[1px] bg-green-200 mt-6 mb-6"></div>

                                    <h3 className="font-bold">ПРОШУ СУД:</h3>

                                    {!Chat.length ?
                                        <div className="mt-2 uppercase text-red-500">Сначала сформируйте чат!</div>
                                        :
                                        !resultText.text ?
                                            <div className="mt-2 uppercase text-red-500">Нет данных для отображения!</div>
                                            :
                                            <div className="mt-2" dangerouslySetInnerHTML={{__html: textRes}}></div>
                                    }

                                    <div className="w-full border border-b-[1px] bg-green-200 mt-6 mb-6"></div>

                                    <div className="mt-4">
                                        {Chat.length ? <button
                                            type="button"
                                            className="mr-3 text-white inline-flex w-full justify-center rounded-md border bg-green-600 border-green-300 bg-white px-4 py-2 text-base font-medium shadow-sm hover:bg-green-200 hover:text-black sm:mt-0 sm:w-auto sm:text-sm transition"
                                            onClick={() => getDoc()}
                                        >
                                            Скачать заявление
                                        </button> : ''}
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={closeRocketModal}
                                        >
                                            Закрыть
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
