import axios from "axios";
import getCookie from "./getCookie";
export default function updateAppealStatementResult(id, statement_result) {
    try {
        axios('/appeals-api/statement-result', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + getCookie('access_token')
            },
            data: {id, statement_result}
        })
    } catch (e) {
        console.error('ERROR request tree', e)
    }
}