import {Dialog, Transition} from '@headlessui/react'
import {Fragment} from 'react'

export default function ClearTextArea({closeDeleteTextAreaModal, isDeleteTextAreaOpen, clearTextInLocalStorageAndInTextarea}) {
    return (
        <>
            <Transition appear show={isDeleteTextAreaOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeDeleteTextAreaModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex justify-center p-4 mt-4  text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-red-900"
                                    >
                                        Очистить шаблон?
                                    </Dialog.Title>
                                    <div className="mt-2 text-red-400">Это действие приведет к полному очищению шаблона!</div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-red-300 bg-white px-4 py-2 text-base font-medium text-red-700 shadow-sm hover:bg-red-50 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={clearTextInLocalStorageAndInTextarea}
                                        >
                                            Очистить шаблон
                                        </button>
                                        <button
                                            type="button"
                                            className="mr-4 inline-flex w-full justify-center rounded-md border border-green-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-green-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={closeDeleteTextAreaModal}
                                        >
                                            Отменить
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
