import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useState} from 'react'
import Chat from '../Chat/Chat'
import DropdownChatMenu from "../Chat/DropdownChatMenu";
import app from "../../App";

export default function ChatModal({open, closeModal, appeal, questions}) {
    const [questionList, setQuestionsList] = useState([])
    function clearChat() {
        setQuestionsList([questions[0]])
    }
    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex justify-center p-4 mt-4  text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-4/6 transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 mb-4"
                                    >
                                        <div className="flex justify-between">
                                            <div><span className="font-bold">ЧАТ</span> | {appeal && appeal.name && appeal.name}</div>
                                            <DropdownChatMenu closeModal={closeModal} clearChat={clearChat}/>
                                        </div>
                                    </Dialog.Title>
                                    <Chat questions={questions} questionList={questionList} setQuestionsList={setQuestionsList} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
