import axios from "axios";
import getCookie from "./getCookie";

export default function updateAppealTree(id, tree) {
    try {
        axios('/appeals-api/tree', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + getCookie('access_token')
            },
            data: {id, tree}
        })
    } catch (e) {
        console.error('ERROR request tree', e)
    }
}