import { BrowserRouter, Routes, Route } from "react-router-dom";
import IndexLayout from './Layouts/index'
import Home from "./pages/Home";
import Text from "./pages/Text";
import NoPage from "./pages/NoPage";

export default function RouteApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<IndexLayout />}>
                    <Route index element={<Home />} />
                    <Route path="text" element={<Text />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
