import axios from "axios";
import getCookie from "./getCookie";
export default function updateAppealStatement(id, statement) {
    try {
        axios('/appeals-api/statement', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + getCookie('access_token')
            },
            data: {id, statement}
        })
    } catch (e) {
        console.error('ERROR request tree', e)
    }
}