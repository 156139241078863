import './App.css';
import RouteApp from "./Route";
import {Provider} from "react-redux";
import {store} from "./store";

function App() {
    return (
        <Provider store={store}>
            <div className="App">
                <RouteApp/>
            </div>
        </Provider>

    );
}

export default App;
