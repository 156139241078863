import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {replaceQuestion} from "../../store/question/questionSlice";
import AlertQuestion from "../create-question/AlertQuestion";

export default function AddOptionOnQuestionModal({closeModal, isOpen, pid, questions}) {

    const [optionName, setOptionName] = useState({value: ''})
    const [alertError, setAlertError] = useState('')
    const [params, setParams] = useState(null)
    const dispatch = useDispatch();

    function editOptionName(val) {
        setOptionName({value: val})
    }

    function addOption() {
        if (!optionName.value) {
            setAlertError('Поле не может быть пустым!');
            closeAlertError()
            return;
        }
        const questionIDX = questions.findIndex(q => q.id === params.pid);
        const Questions = JSON.parse(JSON.stringify(questions));
        if (!Questions[questionIDX].options && !Questions[questionIDX].options.length) return;
        Questions[questionIDX].options.push({
            id: 'Op-' + Math.round(new Date() / 1000),
            child_id: null,
            text: optionName.value
        })
        dispatch(replaceQuestion(Questions))
        closeModal()
    }

    // Закрываем сообщение об ошибки через установленный промежуток времени
    function closeAlertError() {
        setTimeout(() => setAlertError(''), 3000);
    }

    useEffect(() => {
        setParams({pid})
    }, [isOpen])

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex justify-center p-4 mt-4  text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Добавление варианта ответа
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Впишите название ответа и нажмите "Добавить"
                                        </p>
                                        <input
                                            type="text"
                                            name="price"
                                            className="mt-4 block w-full rounded-md border-gray-300 pl-2 pr-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            onChange={ev => editOptionName(ev.target.value)}
                                        />
                                        {
                                            alertError &&
                                            <div className="relative top-[67px]"><AlertQuestion text={alertError}/>
                                            </div>
                                        }
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="mr-4 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={closeModal}
                                        >
                                            Отмена
                                        </button>

                                        {questions.length > 1 && <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => addOption()}
                                        >
                                            Добавить
                                        </button>}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
