import axios from "axios";
import getCookie from "./getCookie";

export default async function getDataAppeals(key) {
    const KEY = getCookie('access_token') || key
    try {
        const result = await axios('/appeals-api', {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + KEY
            }
        })
        return result
    } catch (e) {
        console.error('ERROR request tree', e)
        return false
    }
}