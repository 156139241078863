import {useState} from "react";
import axios from "axios";

export default function AuthForm({setAuthFormStatus, setCookie, getAppeals}) {

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(false)

    function validation(login, password) {
        if (!login || !password) return false
        else return true
    }

    function clearError(time) {
        setTimeout(() => setError(''), time)
    }

    async function Authorization() {
        if (validation(login, password)) {
            setLoader(true)
            try {
                const authResult = await axios("/auth/login", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: { username: login, password: password }
                });
                if (authResult.data.access_token) {
                    await setCookie('access_token', authResult.data.access_token)
                    await getAppeals(authResult.data.access_token)
                }
                else {
                    setError('Что-то пошло не так!')
                    clearError(5000)
                    return
                }
                setAuthFormStatus(false)
                setLoader(false)
            } catch (e) {
                let errorText = ''
                if (e.response.data.statusCode === 401) errorText = 'Неверный логин или пароль!'
                await setError(errorText || e.message)
                clearError(5000)
                console.error('Request error', e);
                setLoader(false)
            }

        }
        else {
            await setError('Поле не может быть пустым!')
            clearError(3000)
        }
    }

    return (
        <section className="h-screen">
            <div className="container px-6 py-12 h-full">
                <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
                    <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0">
                        <img
                            src="/draw2.svg"
                            className="w-full"
                            alt="Phone image"
                        />
                    </div>
                    <div className="md:w-8/12 lg:w-5/12 lg:ml-20">
                        <div className="mb-6">
                            <span className="sr-only">Конструктор вопросов</span>
                            <img
                                className="h-8 w-auto sm:h-10"
                                src="/constructor_logo.svg"
                                alt=""
                            />
                        </div>
                        <div>
                            <div className="mb-6">
                                <input
                                    type="text"
                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                    placeholder="Логин"
                                    onChange={ev => setLogin(ev.target.value)}
                                />
                            </div>

                            <div className="mb-6">
                                <input
                                    type="password"
                                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                    placeholder="Пароль"
                                    onChange={ev => setPassword(ev.target.value)}
                                />
                            </div>

                            {
                                error &&
                                <div className="mb-6">
                                    <p className="text-red-400">{error}</p>
                                </div>
                            }

                            <button
                                type="submit"
                                className="inline-block flex items-center px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out disabled:bg-gray-400"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light"
                                onClick={() => Authorization()}
                                disabled={loader}
                            >
                                {loader && <div className="mr-3">
                                    <svg
                                        className="inline mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                </div>}
                                Авторизация
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}