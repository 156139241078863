const typePayload = (type) => {
    if (!type) return
    const PAYLOAD = {
        OPTION: {
           name: 'Варианты ответов',
           description: 'Создание заранее подготовленных вариантов ответов'
        },

        TEXT: {
            name: 'Текст',
            description: 'Ожидает от пользователя введение текста'
        },

        DATE: {
            name: 'Дата',
            description: 'Ожидает от пользователя введение календарной даты'
        },

        NUMBER: {
            name: 'Число',
            description: 'Ожидает от пользователя введения числа'
        },

        ADDRESS: {
            name: 'Адрес',
            description: 'Ожидает от пользователя введения адреса'
        },

        NAME: {
            name: 'Имя',
            description: 'Ожидает от пользователя введения Ф.И.О.'
        }
    }

    switch (type) {
        case 'options':
            return PAYLOAD.OPTION
        case 'text':
            return PAYLOAD.TEXT
        case 'date':
            return PAYLOAD.DATE
        case 'number':
            return PAYLOAD.NUMBER
        case 'address':
            return PAYLOAD.ADDRESS
        case 'name':
            return PAYLOAD.NAME
        default:
            return PAYLOAD.TEXT
    }
}

export default typePayload