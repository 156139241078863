import {useDispatch} from "react-redux";
import { setAppealsToggle, setAppeal, setQuestionsTree, setPopoverStatus } from "../store/question/questionSlice";

export default function Appeals({appeals, appeal}) {
    let dispatch = useDispatch()

    async function choiceAppeal(item) {
        if (!item) return alert('Системная ошибка!')
        await dispatch(setAppealsToggle(false))
        await dispatch(setAppeal(item))
        if (item.tree && item.tree.length) await dispatch(setQuestionsTree(item.tree))
        else {
            await dispatch(setQuestionsTree([]))
            await dispatch(setPopoverStatus(true))
        }
        if (appeal && appeal._id !== item._id) {
            window.localStorage.removeItem('chat')
            window.localStorage.removeItem('text')
        }
    }

    return (
        <>
            <h1 className="mb-6 mt-4 font-bold">Список "Обращений"</h1>
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {
                appeals.length ? appeals.map(item => (

                    <li className="p-3 sm:pb-4 cursor-pointer hover:bg-green-200"
                        key={item._id}
                        onClick={()=>choiceAppeal(item)}
                    >
                        <div className="flex items-center space-x-4">
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white uppercase">
                                    {item.name}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    { item.tree && item.tree.length ? <span className="text-green-500">Редактровать</span> : <span className="text-red-500">Создать</span> }
                                </p>
                            </div>
                        </div>
                    </li>

                ))
                    :
                    <>
                        <p>Нет данных для отображения! Нужно предварительно создать "Обращения" в Jurisdiction tools</p>
                        <p className="text-gray-400">Возможно закончился токен. Попробуйте перезагрузить страницу или авторизоваться заново.</p>
                    </>
            }
            </ul>
        </>
    )
}