import {Fragment, useEffect} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dropdown({config, type, id, parentID, tag, childID}) {

    function configValidation(label, id) {
        if (id) {
            if (label === 'Отменить перенаправление') return true
            if (label === 'Перенаправить') return false
            else return true
        }
        else {
            if (label === 'Отменить перенаправление') return false
            if (label === 'Перенаправить') return true
            else return true
        }
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button>
                    <EllipsisVerticalIcon className="h-4 w-4 text-gray-500 hover:text-gray-800" aria-hidden="true"/>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {
                            config.map(item => {
                                if (!item.option && configValidation(item.label, childID)) {
                                    return (
                                        <Menu.Item key={item.label}>
                                            {({active}) => (
                                                <div>
                                                    <div
                                                        onClick={() => item.eventClickBind(id, parentID)}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm cursor-pointer'
                                                        )}
                                                    >
                                                        <span
                                                            className={ item.label === 'Удалить' || item.label === 'Отвязать инструмент' || item.label === 'Отменить перенаправление' ? 'text-red-500' : item.label === tag?.label ? 'text-blue-600 font-bold' : '' }>
                                                            {item.label}
                                                        </span>
                                                    </div>
                                                    {item.type === 'Remove' ? <div className="divider"></div> : ''}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    )
                                }
                                else if (type === 'options' && item.show) {
                                    return (
                                        <Menu.Item key={item.label}>
                                            {({active}) => (
                                                <div
                                                    onClick={() => item.eventClickBind(id, parentID)}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm cursor-pointer'
                                                    )}
                                                >
                                                    {item.label}
                                                </div>
                                            )}
                                        </Menu.Item>
                                    )
                                }
                            })
                        }
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
