export default function InputQuestion({setQuestion}) {
    return (
        <div className="w-full">
            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                Впишите текст вопроса:
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                    type="text"
                    name="price"
                    id="price"
                    onChange={(e)=>setQuestion(e.target.value)}
                    className="block w-full rounded-md border-gray-300 pl-2 pr-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
            </div>
        </div>
    )
}
