import {MinusSmallIcon} from "@heroicons/react/20/solid";
import {useEffect} from "react";

export default function InputOptions({minusIdx, remove, id, optionText, text}) {
    useEffect(()=>{
        const VAL = document.getElementById(id)
        VAL.value = text || ''
    })

    return (
        <div className="w-full flex items-center">
            <div className="w-full relative mt-1 rounded-md shadow-sm mr-2">
                <input
                    id={id}
                    type="text"
                    name="price"
                    className="block w-full rounded-md border-gray-300 pl-2 pr-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={ev => optionText({id, text: ev.target.value})}
                />
            </div>
            {
                minusIdx && <div
                    onClick={() => remove(id)}
                    className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full hover:bg-red-100 sm:mx-0 sm:h-7 sm:w-7 cursor-pointer">
                    <MinusSmallIcon className="h-4 w-4 text-red-600" aria-hidden="true"/>
                </div>
            }
        </div>
    )
}
