import { createSlice } from '@reduxjs/toolkit'
import updateAppealTree from "../../tools-scripts/updateAppealTree";

//------------------------------------------------------------------------------------------------------------
// отработать добавление последнего вопроса в списке
// удаление последнего ответа пользователя
// записывать весь чат в local storage
// вместо крестика вывести меню с опциями - "начать чат с начала", "закрыть окно чата"
// clear chat (local storage) if questions !length
// проверить работу мобильного меню в Header (закрыть экран если менее 768px)
// обнулять чат в случае выбора другого appeal
// выводить chat ошибки в облако
// Скрол последнего добавленного элемента до середины экрана questions
// отрегулировать добавление вопросов в чат - учесть совпадающие моменты - добавить во все опции
// реализовать автосылку
// не забыть проверить правильность формирования вопросов при их добавлении
// что если удален вопрос к которому ссылается линк или автолинк (нужно удалять все следы удаляемого в questions)
// вписывать текст в базу данных
// создать технические вопросы в тулзах генерим вопросы обязательные
// технические вопросы - в конструкторе выводим их для выбора
// создаем метки для сущностей ИСТЕЦ, ОТВЕТЧИК, СУММА ИСКА (для имущественных исков) + метки для связи с тулзом (нужно связать вопросы с тулзом)
// логическая разбивка вопрсов в тексте (new text fields)
// логическая обработка ответов содержащих метки tag в наших тулзах (дополняем формальные данные по меткам)
// Cannot set properties of null (setting 'innerHTML') решить вопрос с VALUE и потом сразу с findLogicMethod
// show tag on question box
// избавиться от возврата коретки в начало текста
// не давать возможность вставки в закрытый textarea
// Закрыть возможность вставки селекта внутрь самого селекта 
// disabled select element
// Включить проверку на орфографию
// навешивать disabled или новый value после события change
// Гасить использованные логические option
// Помечать использованные не логические option
// убрать выбор option автоматический (только ручной ввод)
// исключить образование двух атрибутов selected
// добавлять в inactive данные при первичном рендеренге
// устранить баг - при первичном рендеринге неправильно отрабатывает выбор option
// текстовые поля по высоте выровнять с контентом
// вывод готового текста (функция генератор логического текста заявления)
// сделать loaders для загрузок из БД для списка заявлений
// отмечать вопрос и ответ с перелинком по типу 1 -> 7
// удалять связанные тектсовые поля
// не давать редактировать инпуты которые сформировали текстовые поля по опциям
// скрывать кнопку сохранеия если нет изменений в тексте
// авто сохранение каждые 10 секунд работы (не интервал и не таймер, а именно проверка времени последней записи и текущего времени работы)
// следить за логикой удаления вопросов с учетом влияния на формирование содержания текста
// очищать при удалении statement в appeal или вызывать его еще раз
// обновлять состояние appeal при сохранении и роутинге
// Bag last tow elem in chat

// отвязывать линки
// Enter создает вложенный div в текстовом поле, что ломает логику сбора данных из текста (РЕШЕНИЕ - запретить Enter)
// реализовать показ каждого нового поля как абзац для тестовой модалки
// придумать отдельное поле для ввода резулятивной части заявления
// создать отдельную логику обраьотки и сохранения резулятивной части заявления
// исключить выбор опционных вопросов в поле для вода резулятивной части
// отображать предупреждения если нет данных для полей модалки
// накинуть логику обработки html кода резулятивной части и вывода данных в модалку
// генерация готового doc файла

// - перенести показ options box-line н левую сторону
// - решить вопрос в неправильным позиционированием box-line

// - отрегулировать показ (добавление, удаление) раздела "Перенаправить" у вопроса с учетом группировки
// - AUTOLINK?



const initialState = {
    addQuestionStatus: null,
    popoverStatus: false,
    appeal: null,
    questions: [],
    appeals: [],
    appealsToggle: true,
    selectedTechnicalIssues: null,
}

export const questionSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        addQuestionStatusFunc: (state, action) => {
            state.addQuestionStatus = action.payload
            state.questions.map((q, i)=>q.idx = i+1)
        },
        addQuestion: (state, action) => {
            if (state.addQuestionStatus) {
                const index = state.questions.findIndex(el=>el.id === state.addQuestionStatus.parentID)
                if (state.addQuestionStatus.up) state.questions.splice(index, 0, action.payload)
                else state.questions.splice(index+1, 0, action.payload)
                state.addQuestionStatus = null
            }
            else state.questions.push(action.payload)
            state.questions.map((q, i)=>q.idx = i+1)
            setTimeout(()=> {
                const el = document.getElementById(action.payload.id)
                if (el) el.scrollIntoView({behavior: "smooth", block: 'center', inline: 'center'})
            }, 0)
            if (state.appeal) updateAppealTree(state.appeal._id, state.questions)
        },
        replaceQuestion: (state, action)=>{
            state.questions = action.payload
            state.questions.map((q, i)=>q.idx = i+1)
            if (state.appeal) updateAppealTree(state.appeal._id, state.questions)
        },
        setAppeals: (state, action) => {
            state.appeals = action.payload
        },
        setAppealsToggle: (state, action) => {
            state.appealsToggle = action.payload
        },
        setAppeal: (state, action) => {
            state.appeal = action.payload
        },
        setQuestionsTree: (state, action) => {
            state.questions = action.payload
        },
        setPopoverStatus: (state, action) => {
            state.popoverStatus = action.payload
        },
        setTechnicalIssues: (state, action)=> {
            state.selectedTechnicalIssues = action.payload
        }
     },
})

// Action creators are generated for each case reducer function
export const { addQuestionStatusFunc, addQuestion, replaceQuestion, setAppeals, setAppealsToggle, setAppeal, setQuestionsTree, setPopoverStatus, setTechnicalIssues } = questionSlice.actions

export default questionSlice.reducer